import { formatInTimeZone, utcToZonedTime, format } from 'date-fns-tz';
import { isTomorrow, isYesterday, isToday } from 'date-fns';

export const formatDate = (date, tz, mode) => {
  const browserTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let strFormat = browserTz === tz ? 'MMM dd, yyyy • h:mmaaa' : 'MMM dd, yyyy • h:mmaaa zzz';
  if (mode === 'short') {
    strFormat = 'MMM dd';
  }
  return formatInTimeZone(date, tz, strFormat);
};

export const formatStart = (targetDate, tz, mode = 'long') => {
  const now = new Date();
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  const gameIsToday = isToday(targetDate);
  if (gameIsToday) {
    if (mode === 'short') {
      return `Today`;
    }
    return `Today • ${format(targetDate, 'h:mm a', { timeZone })}`;
  }

  const gameIsTomorrow = isTomorrow(targetDate);

  if (gameIsTomorrow) {
    if (mode === 'short') {
      return `Tomorrow`;
    }
    return `Tomorrow • ${format(targetDate, 'h:mm a', { timeZone })}`;
  }

  const gameIsYesterday = isYesterday(targetDate);

  if (gameIsYesterday) {
    if (mode === 'short') {
      return `Yesterday`;
    }
    return `Yesterday • ${format(targetDate, 'h:mm a', { timeZone })}`;
  }


  return formatDate(targetDate, tz, mode); // 'MMM dd, yyyy • h:mmaaa zzz'
};

