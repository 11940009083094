import { useRef } from 'react';

import { SeekMessage } from '@/types/control';

import useWebSocket from './useWebSocket';
import { WebSocketRefHook, withStateFromRef } from '../withStateFromRef';
import { Tracking } from '../../services/tracking';

export const useTimestampRef: WebSocketRefHook<Date> = (options = {}) => {
  const ref = useRef<Date>(new Date());
  const activityRef = useRef<number>(0);

  const handleChange = (value: Date) => {
    ref.current = value;

    if (options.onChange) {
      options.onChange(value);
    }
  };

  const handleActivePlaybackUpdate = (timestamp: number) => {
    if (timestamp !== activityRef.current) {
      activityRef.current = timestamp;
      Tracking.getInstance().track('Playback Progress', {
        category: 'Playback',
        unit: 'minutes',
        type: 'playback',
        minutes: timestamp,
      });
    }
  };

  const socket = useWebSocket<SeekMessage>('/control/0.1.0', {
    onMessage: (message) => {
      if (message.type !== 'Seek') return;
      const date = new Date(Math.round(message.target_time / 1_000_000));
      handleActivePlaybackUpdate(date.getTime());
      handleChange(date);
    },
  });

  const setTimestamp = (timestamp: Date) => {
    if (timestamp.getTime() < 0) return;
    socket.send({
      type: 'Seek',
      target_time: timestamp.getTime() * 1_000_000,
    });

    handleChange(timestamp);
  };

  return [ref, setTimestamp];
};

export const useTimestampState = withStateFromRef(useTimestampRef,'useTimestampState');
