import useAllClipTagsQuery from '@/features/clipTags/hooks/useAllClipTagsQuery';
import { PANEL_PADDING } from '@/features/games/constants';
import { Autocomplete, TextField } from '@mui/material';
import React, { useEffect } from 'react';

export const TagsFilter = ({ setTagsFilter, isEditing }) => {
  const clipTagsQuery = useAllClipTagsQuery();

    useEffect(() => {
        clipTagsQuery.refetch();
    }, [isEditing]);

  return (
    <>
      {clipTagsQuery.data && clipTagsQuery.data.length > 0 && (
        <Autocomplete
          multiple
          sx={{ mx: PANEL_PADDING, mt: 2, mb: 2 }}
          options={clipTagsQuery.data}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label="Filter by Tags" />
          )}
          onChange={(_, val) => setTagsFilter(val)}
          disabled={isEditing}
          getOptionLabel={(option) => option.name}
        />
      )}
    </>
  );
};
