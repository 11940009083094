import { useMutation } from '@tanstack/react-query';

import { put } from '@truefit/http-utils';
import { User } from './getCurrentUser';
import getCurrentUser from './getCurrentUser';

const useUpdateUser = () => {
  const getCurrentUserQuery = getCurrentUser();
  return useMutation({
    mutationFn: async (user: User) => {
      const response = await put<User>('user', { ...user });
      return response.data;
    },
    onSuccess: () => {
      getCurrentUserQuery.refetch();
    },
  });
};

export default useUpdateUser;
