import React, { useEffect, useState } from 'react';
import { IconButton, Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { Delete, Edit, MoreVert } from '@mui/icons-material';
import { User } from '../types';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
const ActionMenu: React.FC<{ user: User; onEdit: () => void; onDelete: () => void, onDisable: ()=> void }> = ({
  user,
  onEdit,
  onDelete,
  onDisable
}) => {

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton edge="end" onClick={handleMenuOpen}>
        <MoreVert />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
        {/* Edit User Group Action */}
        <MenuItem onClick={() => {
          handleMenuClose();
          onEdit();
        }}>
          <ListItemIcon>
            <Edit fontSize="small" />
          </ListItemIcon>
          <ListItemText>Edit User Group</ListItemText>
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleMenuClose();
            onDisable();
          }}
        >
          <ListItemIcon>
            {user.disabled ? <PersonAddAltIcon fontSize="small" /> :<NoAccountsIcon fontSize="small"  />}
            
          </ListItemIcon>
          <ListItemText>{user.disabled?'Enable':'Disable'} Access for User</ListItemText>
        </MenuItem>

        {/* Delete User Action */}
        <MenuItem
          onClick={() => {
            handleMenuClose();
            onDelete();
          }}
          sx={{ color: 'error.main' }} // Error styling for destructive action
        >
          <ListItemIcon>
            <Delete fontSize="small" sx={{ color: 'error.main' }} />
          </ListItemIcon>
          <ListItemText>Delete User</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default ActionMenu;