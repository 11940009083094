import { useQuery } from '@tanstack/react-query';
import { get } from '@truefit/http-utils';
import useCurrentGameId from '@/shared/hooks/useCurrentGameId';
import {isUUID} from 'validator';


export const getQueryKey = (gameId: string) => ['Games', gameId, 'Cameras'];

const useCamerasQuery = () => {
  const gameId = useCurrentGameId();

  return useQuery({
    queryKey: getQueryKey(gameId),
    queryFn: async (): Promise<any> => {
      if (!gameId || !isUUID(gameId)) {
        return {};
      }

      return await get<any>(`games/${gameId}/cameras`);
    },
    enabled: false,
    gcTime: Infinity,
    placeholderData: {},
  });
};

export default useCamerasQuery;
