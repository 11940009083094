import * as React from 'react';

import KeyboardArrowDownTwoToneIcon from '@mui/icons-material/KeyboardArrowDownTwoTone';
import { Collapse, CollapseProps, Divider, IconButton, Stack, Box, Grow, Fade } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { PANEL_PADDING } from '../constants';
import SectionTitle from '@/shared/components/SectionTitle';

type Props = {
  title: string | React.ReactNode;
  titleIcon?: React.ReactNode;
  children: React.ReactNode;
  CollapseProps?: CollapseProps;
  initialExpandedState?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  dropdown: {
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.standard,
    }),
    color: theme.palette.grey[400],
  },
  dropdownOpen: {
    transform: 'rotate(-180deg)',
  },
  dropdownClosed: {
    transform: 'rotate(0)',
  },
}));

const ViewingToolsMenuExpandablePanel = React.forwardRef(
  (
    { title, children,titleIcon, CollapseProps = {}, initialExpandedState = true }: Props,
    ref: React.RefObject<HTMLDivElement>,
  ) => {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(initialExpandedState);
 

    return (
      <>
        <Stack
          direction="row"
          sx={{ mt: 0, ml: PANEL_PADDING, mr: PANEL_PADDING - 1 }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%'
          }}>
           <SectionTitle
              title={title as string}
              padding={0}
              mt={0}
              mb={0}
            />

            {titleIcon}
            </Stack>
          <IconButton size="small" onClick={() => setExpanded(!expanded)}>
            <KeyboardArrowDownTwoToneIcon
              className={[
                classes.dropdown,
                expanded ? classes.dropdownOpen : classes.dropdownClosed,
              ].join(' ')}
            />
          </IconButton>
        </Stack>
        <Divider />
        <Fade in={expanded}>
          <Collapse
            unmountOnExit
            {...CollapseProps}
            sx={{ flexShrink: 0, ...(CollapseProps.sx || {}) }}
            in={expanded}
          >
            <Box mt={0}>{children}</Box>
          </Collapse>
        </Fade>
      </>
    );
  },
);

export default ViewingToolsMenuExpandablePanel;
