import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import React, { useEffect, useState } from 'react';
import { useAllUsersQuery } from '../hooks/useAllUsersQuery';
import { flattenUsers } from '../services/flattenUsers';
import { UsersTable } from './UsersTable';

import { Group, User } from '../types';
import { UserStateContext } from '@/shared/components/UserStateProvider';
import useOrgGroupsQuery from '../hooks/useOrgGroupsQuery';
import useUpdateOrgMutation from '../hooks/updateOrgMutation';
import useInviteUserToOrganizationMutation from '../hooks/inviteUserToOrganizationMutation';
import LoadingButton from '@mui/lab/LoadingButton';
import useDeleteUserMutation from '../hooks/useDeleteUserMutation';
import useDisableUserMutation from '../hooks/useDisableUserMutation';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

const UserManagement: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [groups, setGroups] = useState<Group[]>([]);
  const [inviteEmail, setInviteEmail] = useState<string>('');
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [groupId, setGroup] = useState<number>(-1);
  const [inviteOpen, setInviteOpen] = useState<boolean>(false);
  const { setCurrentOrg, currentOrg } = React.useContext(UserStateContext);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false); // State for delete dialog
  const [disableDialogOpen, setDisableDialogOpen] = useState<boolean>(false); // State for disable dialog
  const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false); // State for edit dialog

  const updateOrganizationMutation = useUpdateOrgMutation();
  const inviteUserToOrganizationMutation = useInviteUserToOrganizationMutation();
  const disableUserMutation = useDisableUserMutation(currentOrg?.id);
  const deleteUserMutation = useDeleteUserMutation(currentOrg?.id);
  const allUsersQuery = useAllUsersQuery(currentOrg?.id);
  const orgGroups = useOrgGroupsQuery(currentOrg?.id);
  const [confirmToggleDisabled, setConfirmToggleDisabled] = useState<boolean>(false);

  const handleInvite = async () => {
    if (inviteEmail && groupId) {
      await inviteUserToOrganizationMutation.mutateAsync({ groupId, email: inviteEmail });
      setInviteEmail('');
      setGroup(-1);
      setInviteOpen(false);
    }
  };

  const setDefaultRole = async (groupId: number) => {
    const org = await updateOrganizationMutation.mutateAsync({ defaultGroupId: groupId });
    setCurrentOrg(org);
  };

  useEffect(() => {
    allUsersQuery.refetch();
  }, []);

  useEffect(() => {
    if (allUsersQuery.status === 'success') {
      setUsers(flattenUsers(allUsersQuery.data));
    }
  }, [allUsersQuery.status]);

  useEffect(() => {
    if (orgGroups.status === 'success') {
      setGroups(orgGroups.data);
    }
  }, [orgGroups.status]);

  const handleDelete = (userId: number) => {
    const user = users.find((u) => u.id === userId);
    if (user) {
      setSelectedUser(user);
      setDeleteDialogOpen(true);
    }
  };

  const handleDisable = (userId: number) => {
    const user = users.find((u) => u.id === userId);
    if (user) {
      setSelectedUser(user);
      setDisableDialogOpen(true);
    }
  };

  const handleEditUserGroup = (user: User) => {
    setSelectedUser(user);
    setGroup(user.groupId);
    setEditDialogOpen(true);
  }


  const confirmDelete = async () => {
    if (selectedUser) {
      await deleteUserMutation.mutateAsync({ userId: selectedUser.id });
      setUsers((prevUsers) => prevUsers.filter((user) => user.id !== selectedUser.id));
      setDeleteDialogOpen(false);
      setSelectedUser(null);
    }
  };

  const confirmAccessToggle = async () => {
    if (selectedUser) {
      setConfirmToggleDisabled(true);
      await disableUserMutation.mutateAsync({ userId: selectedUser.id });
      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.id === selectedUser.id ? { ...user, disabled: !user.disabled } : user,
        ),
      );

      setConfirmToggleDisabled(false);
      setDisableDialogOpen(false);
      setSelectedUser(null);
    }
  };

  const handleCancelEdit = () => {
    setEditDialogOpen(false);
    setSelectedUser(null);
  }

  return (
    <Box>
      <Stack direction="column" justifyContent="center" spacing={2} p={3}>
        <Typography variant="subtitle1">Organization Members</Typography>
      </Stack>

      <Stack direction="column" justifyContent="center" alignItems="flex-end" spacing={2} p={3}>
        <Button variant="contained" color="primary" onClick={() => setInviteOpen(true)} startIcon={<PersonAddIcon />}>
          Invite User
        </Button>
      </Stack>

      {users && (
        <UsersTable handleEditUserGroup={handleEditUserGroup} users={users} handleDelete={handleDelete} handleDisable={handleDisable} />
      )}

      <Box>
        <Stack direction="column" alignItems="left" spacing={2} p={3}>
          <Typography sx={{ minWidth: 400 }} variant="subtitle1">
            Default Members Role
          </Typography>
          <Typography sx={{ minWidth: 400 }} variant="body2">
            Set the default role for new members added to the organization.
          </Typography>
          <Select
            sx={{
              maxWidth: 400,
              mt: 2,
              mb: 2,
            }}
            value={currentOrg.defaultGroupId}
            onChange={(e) => setDefaultRole(e.target.value as number)}
            fullWidth
          >
            {groups &&
              groups.map((group) => (
                <MenuItem key={group.id} value={group.id}>
                  {group.name}
                </MenuItem>
              ))}
          </Select>
        </Stack>
      </Box>
      {/* Edit User Dialog */}
      <Dialog open={editDialogOpen} onClose={() => setSelectedUser(null)}>
        <DialogTitle>Edit User</DialogTitle>
        <DialogContent sx={{ minWidth: 450 }}>
          <Stack spacing={2} direction="column">
            <FormControl fullWidth>
              <FormLabel htmlFor="email">Email</FormLabel>
              <TextField id="email" value={selectedUser?.email} disabled fullWidth />
            </FormControl>
            <FormControl fullWidth>
              <FormLabel htmlFor="group">Group</FormLabel>
              <Select
                id="group"
                value={groupId}
                onChange={(e) => setGroup(e.target.value as number)}
                fullWidth
              >
                {groups &&
                  groups.map((group) => (
                    <MenuItem key={group.id} value={group.id}>
                      {group.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelEdit}>Cancel</Button>
          <LoadingButton
            loading={inviteUserToOrganizationMutation.status === 'pending'}
            loadingPosition="start"
            variant="outlined"
            onClick={handleInvite}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>

      {/* Disable Confirmation Dialog */}
      <Dialog open={disableDialogOpen} onClose={() => setDisableDialogOpen(false)}>
        <DialogTitle>
          {selectedUser?.disabled === false ? 'Disable' : 'Enable'} access for{' '}
        </DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to {selectedUser?.disabled === false ? 'disable' : 'enable'}{' '}
            access for <strong>{selectedUser?.email}</strong>?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDisableDialogOpen(false)}>Cancel</Button>
          <Button
            variant="contained"
            color="error"
            disabled={confirmToggleDisabled}
            onClick={confirmAccessToggle}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete <strong>{selectedUser?.email}</strong>?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button variant="contained" color="error" onClick={confirmDelete}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      {/* Invite User Dialog */}
      <Dialog open={inviteOpen} onClose={() => setInviteOpen(false)}>
        <DialogTitle>Invite User</DialogTitle>
        <DialogContent sx={{ minWidth: 450 }}>
          <Stack spacing={2} direction="column">
            <FormControl fullWidth>
              <FormLabel htmlFor="email">Email</FormLabel>
              <TextField
                id="email"
                type="email"
                value={inviteEmail}
                onChange={(e) => setInviteEmail(e.target.value)}
                fullWidth
              />
            </FormControl>
            <FormControl fullWidth>
              <FormLabel htmlFor="group">Group</FormLabel>
              <Select
                id="group"
                value={groupId}
                onChange={(e) => setGroup(e.target.value as number)}
                fullWidth
              >
                {groups &&
                  groups.map((group) => (
                    <MenuItem key={group.id} value={group.id}>
                      {group.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setInviteOpen(false)}>Cancel</Button>
          <LoadingButton
            loading={inviteUserToOrganizationMutation.status === 'pending'}
            loadingPosition="start"
            startIcon={<SendIcon />}
            variant="outlined"
            onClick={handleInvite}
          >
            Invite
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default UserManagement;
