import { parseISO } from 'date-fns';

import { ClipModel, ClipModelFromServer } from '../types';

const toClipModel = (clipModelFromServer: ClipModelFromServer): ClipModel => {
  const startTimestamp = typeof clipModelFromServer.startTimestamp === 'string'
    ? parseISO(clipModelFromServer.startTimestamp)
    : clipModelFromServer.startTimestamp;
  const endTimestamp = clipModelFromServer.endTimestamp && typeof clipModelFromServer.endTimestamp === 'string'
    ? parseISO(clipModelFromServer.endTimestamp)
    : clipModelFromServer.endTimestamp;
  const createdAt = typeof clipModelFromServer.createdAt === 'string'
    ? parseISO(clipModelFromServer.createdAt)
    : clipModelFromServer.createdAt;

  return {
    ...clipModelFromServer,
    startTimestamp,
    endTimestamp,
    createdAt,
    type: clipModelFromServer.mediatype,
  };
};

export default toClipModel;
