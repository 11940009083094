import React, { useEffect } from 'react';

import useServerStateContext from '@/shared/hooks/useServerStateContext';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import PtzJoystick from './PtzJoystick';

import { usePTZSocket } from '@/shared/hooks/websocket/usePTZSocket';
import { ViewType } from '@/features/camera/types/VideoSource';
import SectionTitle from '@/shared/components/SectionTitle';
import { PANEL_PADDING } from '../constants';

const OPACITY_WHEN_DISABLED = 0.4;

const PtzControls = () => {
  const { selectedCameraId: cameraId, viewType } = useServerStateContext();
  const { setValue: setPtzVelocity } = usePTZSocket();
  const disabled =
    cameraId === null || typeof cameraId === 'undefined' || viewType === ViewType.MOSAIC;

  const [joystick, setJoystick] = React.useState(null);

  const handlePanTiltChange = (pan: number, tilt: number) => {
    setPtzVelocity({
      pan,
      tilt,
      zoom: 0,
    });
  };

  function gamepadHandler(e, connected) {
    const gamepad = e.gamepad;
    // Note:
    // gamepad === navigator.getGamepads()[gamepad.index]

    if (connected) {
      setJoystick(gamepad);
    } else {
      setJoystick(null);
    }
  }

  useEffect(() => {
    window.addEventListener(
      'gamepadconnected',
      (e) => {
        gamepadHandler(e, true);
      },
      false,
    );
    window.addEventListener(
      'gamepaddisconnected',
      (e) => {
        gamepadHandler(e, false);
      },
      false,
    );

    return () => {
      window.removeEventListener(
        'gamepadconnected',
        (e) => {
          gamepadHandler(e, true);
        },
        false,
      );
      window.removeEventListener(
        'gamepaddisconnected',
        (e) => {
          gamepadHandler(e, false);
        },
        false,
      );
    };
  }, []);

  return (
    <>
      <SectionTitle
        title={'PTZ Controls'}
        padding={PANEL_PADDING}
        tooltip="Pan and tilt using your keyboard or by clicking and dragging on the virtual joystick
          below."
      />

      <Box
        sx={{ position: 'relative', mt: 2, mx: 3, opacity: disabled ? OPACITY_WHEN_DISABLED : 1 }}
      >
        <PtzJoystick disabled={disabled} onChange={handlePanTiltChange} />
      </Box>
      <Box
        sx={{
          mx: 3,
          mt: 6,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {joystick && <Typography> {joystick.id}</Typography>}
      </Box>
    </>
  );
};

export default PtzControls;
