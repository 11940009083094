import * as React from 'react';
import {isUUID} from 'validator';

import { useQueryClient } from '@tanstack/react-query';
import {
  ClipStateProvider,
  KeyboardShortcutsDialogProvider,
  ServerStateProvider,
} from '@/shared/components';
import ConfirmationDialogProvider from '@/shared/components/ConfirmationDialogProvider';

import useCurrentGameId from '@/shared/hooks/useCurrentGameId';

import useGameDetailsQuery from '../hooks/useGameDetailsQuery';
import useGameSwitchQuery from '../hooks/useGameSwitchQuery';
import AppPage from '../../../shared/components/PageComponents/AppPage';
import ScoreboardData from './ScoreboardData';
import ViewingToolsMenu from './ViewingToolsMenu';

import { getQueryKey as useCamerasQueryKey } from '@/features/camera/hooks/useCamerasQuery';
import { useDocumentTitle } from '@/shared/hooks/useDocumentTitle';

import LoadingGame from './LoadingGame';
import GamePageContents from './GamePageContents';

const DRAWER_WIDTH = 420;

const GamePage = () => {
  const gameId = useCurrentGameId();
  const gameQuery = useGameDetailsQuery(gameId);
  const { data: game } = gameQuery;
  const switchGameQuery = useGameSwitchQuery(gameId);
  const queryClient = useQueryClient();

  const cameraQueryKey = useCamerasQueryKey(gameId);
  const [initialized, setInitialized] = React.useState(false);

  useDocumentTitle(`${game?.name} - Game`);

  React.useEffect(() => {
    if (gameId && isUUID(gameId)) {
      switchGameQuery.refetch();
    }
  }, [gameId]);

  React.useEffect(() => {
    if (switchGameQuery.status === 'success') {
      queryClient.invalidateQueries({ queryKey: cameraQueryKey });

      if (!initialized) {
        if (game) {
          setInitialized(true);
        }
      }
    }
  }, [switchGameQuery.status]);

  return (
    <ConfirmationDialogProvider>
      <KeyboardShortcutsDialogProvider>
        <ServerStateProvider>
          <LoadingGame />
          {switchGameQuery.status === 'success' && (
            <ClipStateProvider>
              <AppPage
                title={game?.name || 'Loading...'}
                pageMenuWidth={DRAWER_WIDTH}
                renderPageMenu={(open, setOpen) => (
                  <ViewingToolsMenu open={open} setOpen={setOpen} width={DRAWER_WIDTH} />
                )}
                renderSummaryData={() => <ScoreboardData data={game} />}
              >
                <GamePageContents gameId={game?.id} />
              </AppPage>
            </ClipStateProvider>
          )}
        </ServerStateProvider>
      </KeyboardShortcutsDialogProvider>
    </ConfirmationDialogProvider>
  );
};

export default GamePage;
