import * as React from 'react';

import { Box, Typography } from '@mui/material';

import { CognitoContext } from '@/features/authentication/components/CognitoProvider';
import { KeyboardShortcutsDialog } from '@/shared/components';
import useClipStateContext from '@/shared/hooks/useClipStateContext';
import useServerStateContext from '@/shared/hooks/useServerStateContext';

import useCameraPtzKeyboardBindings from '../../camera/hooks/useCameraPtzKeyboardBindings';
import useCameraSelectionBindings from '../../camera/hooks/useCameraSelectionBindings';

import { PlayerControls } from '../../video/components';
import useFullscreen from '../../video/hooks/useFullscreen';
import usePlayer from '../../video/hooks/usePlayer';
import {
  ACCEPTABLE_GRID_SIZES,
  CLIP_MODE_ALERT_HEIGHT,
  PLAYER_CONTROLS_HEIGHT,
  TOOLBAR_HEIGHT,
  VIDEO_PLAYER_ID,
} from '../constants';
import WheelZoom from './WheelZoom';
import WebrtcMetrics from './WebrtcMetrics';
import RotatingIcon from '@/shared/components/RotatingIcon';
import { usePTZStore } from '@/shared/hooks/store/usePTZStore';
import { usePTZSocket } from '@/shared/hooks/websocket/usePTZSocket';
import { ViewType } from '@/features/camera/types/VideoSource';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useRef, useState } from 'react';
type Tuple = [zoom: number, step: number];

const HOLD_TIME = 500; // Time in ms to adjust the factor on hold.


// Example usage:
const tuples: Tuple[] = [
  [1, 7500],
  [10, 750],
  [80, 500]
];

function interpolateStep(zoom: number): number {
  // Ensure the tuples are sorted by zoom value
  tuples.sort((a, b) => a[0] - b[0]);

  const n = tuples.length;

  // Handle edge cases where the zoom is outside the range
  if (zoom <= tuples[0][0]) return tuples[0][1];
  if (zoom >= tuples[n - 1][0]) return tuples[n - 1][1];

  // Find the surrounding points for interpolation
  for (let i = 0; i < n - 1; i++) {
    const [z1, s1] = tuples[i];
    const [z2, s2] = tuples[i + 1];

    if (zoom >= z1 && zoom <= z2) {
      // Perform linear interpolation
      const t = (zoom - z1) / (z2 - z1); // interpolation factor
      return Math.round(s1 + t * (s2 - s1)); // round to the nearest integer
    }
  }

  throw new Error("Unexpected error in interpolation.");
}


const GamePageContents = () => {

  const { ref: fullscreenRef, requestFullscreen, isFullscreen, exitFullscreen } = useFullscreen();
  const videoControls = React.useRef<HTMLDivElement>(null);

  const singleCameraRef = React.useRef(null);
  const { cognitoContext } = React.useContext(CognitoContext);
  const [statsReport, setStatsReport] = React.useState<RTCStatsReport>();

  const serverState = useServerStateContext();
  const { selectedCamera, selectCamera, viewType, availableCameras } = serverState;
  const clipState = useClipStateContext();

  const [toggleStatsVisibility, setToggleStatsVisibility] = React.useState(false);

  const { getValue, store } = usePTZStore();
  const { setValue } = usePTZSocket();

  const [selectedCameraPTZ, setSelectedCameraPTZ] = React.useState({ pan: 0, tilt: 0, zoom: 0 });

  const { debugData } = useFlags();

  const streamUrl = `wss://${cognitoContext?.sessionHandler}.aws.c360live.com/app/stream`;

  usePlayer({
    elId: VIDEO_PLAYER_ID,
    streamUrl,
    onWebrtcStatsChange: setStatsReport,
  });

  const updateWindowDimensions = () => {
    if (videoControls.current && singleCameraRef.current) {
      const videoContainerBoundaries = singleCameraRef.current.getBoundingClientRect();
      const videoControlsBoundaries = videoControls.current.getBoundingClientRect();
      const w = videoContainerBoundaries.width;
      const h = videoContainerBoundaries.height - videoControlsBoundaries.height;
    }
  };

  React.useEffect(() => {
    updateWindowDimensions();
  }, [videoControls.current, singleCameraRef.current]);

  // Update dimensions when the component mounts
  React.useEffect(() => {
    // Add event listener for window resize
    window.addEventListener('resize', updateWindowDimensions);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', updateWindowDimensions);
    };
  }, []); // Empty dependency array ensures this effect runs only once on mount

  const gridSize = ACCEPTABLE_GRID_SIZES.find((size) => size >= availableCameras.length);
  const gridEdgeSize = Math.max(gridSize / 2, 1);

  
  useCameraSelectionBindings((cameraId) => {
    selectCamera(cameraId, 'Keyboard Shortcut');
  });
  
  useCameraPtzKeyboardBindings((ptzDiff) => {
    if (viewType === ViewType.MOSAIC) {
      return;
    }

    if(typeof selectedCamera?.idx === 'undefined') 
      return;
    const {zoom} = getValue(selectedCamera.idx);

    const ZOOM_FACTOR = 0.5; // two times

    const newValue = {
      pan: ptzDiff.panDiff / interpolateStep(zoom) || 0,
      tilt: ptzDiff.tiltDiff / interpolateStep(zoom) || 0,
      zoom: ptzDiff.zoomDiff / ZOOM_FACTOR || 0,
    };

    setValue(newValue);
  });

  const getCameraName = React.useCallback(() => selectedCamera?.name || '', [selectedCamera]);

  React.useEffect(() => {
    // What is the point of this ?
    if (!availableCameras.length) return;
    availableCameras.forEach((c) => {
      if (store[c.id] !== undefined) {
        c.pan = store[c.id].pan;
      }
    });
  }, [store]);

  React.useEffect(() => {
    if (getValue && typeof selectedCamera?.idx==='number') {
      
      setSelectedCameraPTZ(getValue(selectedCamera.idx));
    }
  }, [getValue, selectedCamera?.idx]);

  const [showToolbar, setshowToolbar] = useState<boolean>(true);

  const hideToolbarTimeout = useRef<typeof setTimeout>(null);

  const mouseIn = useRef(false);

  const show = () => {
    //console.debug("show toolbar: init");
    setshowToolbar(true);

    if (hideToolbarTimeout?.current) {
      clearTimeout(hideToolbarTimeout.current);
    }

    hideToolbarTimeout.current = window.setTimeout(() => {
      if (!mouseIn.current) hide();
    }, 2000);
  };

  const hide = () => {
    setshowToolbar(false);
  };

  const absolute: 'absolute' | 'relative' | 'fixed' = 'absolute';

  const gradientBackground = {
    position: absolute,
    width: '100%',
    left: 0,
    bottom: 0,
    height: '100%',
    backgroundImage:
      'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAC+CAYAAAD0iWUpAAAAAXNSR0IArs4c6QAAAaJJREFUOE890PtrT3Ecx/H3mxnGMJvNLsZsZhvmtjsbYzP3lLSSpCQlSUlKkpKU1lJaStJKUvJH6vH5fp3zw7PX5f0+n885EeXJCnTWgtwgLdhYR2aDvoHdFJHZaLTR+GZZwZYaMmKrYYgmWZPhbVTB9grNVLP37TC8ky3Y9R/ZEpnRwu6uI7NVVtBmrU2xhy1od1q7rEPbIdsr62Q7FV1Ul3O7IyEyemQ92n01ZEavrJfa779AHKAg+nxlH3vQC/pl/YYH2AG7h2QFg+yg4rANiCHDQ9SwbNhdRqgRc0dsHK0hIo5VGKVGFcepghPWIE5WOCUrOC0rGLM2Ro0rJtxggp2kJqkpasoNpl1oWjZjeIY9o4A86/MhZo3MKubYOeqcjfMKyHlqXnuhwkUZ5IKNBXaRXfTmSxTEkgstUZdlV+rIuCqDvOZCENfriLhB3TRyy8ht9g67DHedcU9xn31APaQewWPDT6inrvuMeg4vrL2kXtl4zb5h38I7eG/jA/WR+mRuhVqFz4ov1Br11UHfqO/wA9Z9zE8/+5e53/BH8fcfIHIrEq6e9rEAAAAASUVORK5CYII=")',
    display: 'flex',
    backgroundRepeat: 'repeat-x',
    backgroundPosition: 'bottom',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: showToolbar ? 1 : 0,
    transition: (theme) => `opacity ${showToolbar ? '0.1s' : '0.5s'} cubic-bezier(.4,0,1,1)`,
  };

  return (
    <Box
      ref={fullscreenRef}
      sx={() => ({
        display: 'flex',
        overflow: 'hidden',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      })}
      onMouseEnter={() => show()}
      onMouseMove={show}
    >
      <Box
        sx={{
          width: '100%',
          maxWidth: `calc((100vh - ${
            (isFullscreen ? PLAYER_CONTROLS_HEIGHT : PLAYER_CONTROLS_HEIGHT + TOOLBAR_HEIGHT) +
            (clipState?.state.isEditing ? CLIP_MODE_ALERT_HEIGHT : 0)
          }px) * 1.7777)`, // keeps a 16/9 aspect ratio based on available height
          maxHeight: '100vh',
          position: 'relative',
        }}
        ref={singleCameraRef}
      >
        <WheelZoom>
          <Box id={VIDEO_PLAYER_ID} />
          {!isFullscreen && <div style={gradientBackground}></div>}
          {viewType === ViewType.MOSAIC && (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                aspectRatio: '16/9',
                display: 'grid',
                gridTemplateColumns: `repeat(${gridEdgeSize}, 1fr)`,
              }}
            >
              {availableCameras.length > 0 &&
                availableCameras.map((c) => (
                  <Box
                    key={c.id}
                    onClick={() => selectCamera(c, 'Video Player')}
                    sx={{
                      color: 'white',
                      display: 'flex',
                      alignItems: 'flex-start',
                      justifyContent: 'space-between',
                      flexDirection: 'column',
                      fontWeight: 'bold',
                      fontSize: 26,
                      cursor: 'pointer',
                      position: 'relative',
                      transition: 'background-color 0.2s ease-in-out',
                      '&:hover': {
                        backgroundColor: 'rgba(0,0,0,0.1)',
                      },
                    }}
                  >
                    <Box
                      m={1}
                      sx={{
                        padding: '1px',
                        borderRadius: '4px',
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        opacity: 0.2,
                        '&:hover': {
                          opacity: 1,
                        },
                        transition: 'opacity 0.2s ease-in-out',
                      }}
                    >
                      <RotatingIcon size={24} angle={c.pan - 45} />
                    </Box>
                    <Box
                      m={1}
                      sx={{
                        backgroundColor: 'rgba(0,0,0,0.4)',
                        padding: '5px',
                        borderRadius: '4px',
                        position: 'absolute',
                        bottom: 0,
                        right: 0,
                      }}
                    >
                      <Typography fontSize={10}>{c.name} </Typography>
                    </Box>
                  </Box>
                ))}
            </Box>
          )}

          {viewType !== ViewType.MOSAIC && (
            <Box
              m={1}
              sx={{
                backgroundColor: 'rgba(0,0,0,0.4)',
                padding: '5px',
                borderRadius: '4px',
                position: 'absolute',
                bottom: 100,
                right: 0,
              }}
            >
              {debugData && (
                <>
                  <pre>P {selectedCameraPTZ?.pan} </pre>
                  <pre>T {selectedCameraPTZ?.tilt} </pre>
                  <pre>Z {selectedCameraPTZ?.zoom} </pre>
                </>
              )}
              <Typography fontSize={12}>{getCameraName()} </Typography>
            </Box>
          )}
        </WheelZoom>

        <WebrtcMetrics toggleStatsVisibility={toggleStatsVisibility} statsReport={statsReport} />

        <Box
          position="absolute"
          sx={{
            bottom: 0,
            right: 0,
            width: '100%',
            opacity: showToolbar ? 1 : 0,
            transition: (theme) => `opacity ${showToolbar ? '0.1s' : '0.5s'} ease-in-out`,
          }}
          ref={videoControls}
        >
          <PlayerControls
            requestFullscreen={requestFullscreen}
            exitFullscreen={exitFullscreen}
            isFullscreen={isFullscreen}
            onStatsToggle={() => setToggleStatsVisibility(!toggleStatsVisibility)}
          />
        </Box>
      </Box>

      {/* this has to be inside of the fullscreen component so that it will show in fullscreen mode */}
      <KeyboardShortcutsDialog />
    </Box>
  );
};

export default GamePageContents;
