import { parseISO } from 'date-fns';
import { motion } from 'framer-motion';
import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import { useQuery } from '@tanstack/react-query';
import { get } from '@truefit/http-utils';
import { GameModelFromServer } from '../types';

import { UserStateContext } from '@/shared/components/UserStateProvider';
import { useTeamLogoPath } from '@/shared/hooks/useTeamLogoPath';
import { formatStart } from '@/shared/services/formatStart';
import { getDuration } from '@/shared/services/getDuration';
import { Tracking } from '@/shared/services/tracking';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { LiveIndicator } from './LiveIndicator';
import { UpcomingIndicator } from './upcomingIndicator';
import './styles/halftone.css';

type Props = {
  game: GameModelFromServer;
  cardWidth: number;
  cardHeight: number;
};

const calculateRefetchInterval = (startDate: Date): number => {
  const minutesUntilGame = (new Date(startDate).getTime() - Date.now()) / (1000 * 60);
  if (minutesUntilGame > 1440) return 1000 * 60 * 60; // 1 hour
  if (minutesUntilGame > 60) return 1000 * 60 * 10; // 10 minutes
  if (minutesUntilGame > 10) return 1000 * 60; // 1 minute
  return 1000 * 3; // 3 seconds
};

const GameCard = ({ game, cardWidth, cardHeight }: Props) => {
  const start = parseISO(game.startTimestamp);
  const end = parseISO(game.endTimestamp);
  const userContext = useContext(UserStateContext);
  const { debugData } = useFlags();

  const [gameStatus, setGameStatus] = useState(game.status);
  const [isHovered, setIsHovered] = useState(false);

  const formattedStartDate = formatStart(start, userContext?.user.tz);
  const opacity = gameStatus === 'scheduled' ? 0.8 : 0.9;

  const isClickable = ['live', 'recorded'].includes(gameStatus); // Condition to make the card clickable

  const updateGameStatus = async () => {
    if (Number.isNaN(game.id)) return {};
    const { data } = await get<GameModelFromServer>(`games/${game.uniqueId}?polling=true`);
    setGameStatus(data.status);
    return data;
  };

  useQuery({
    queryKey: ['GamesStatus', game.id],
    queryFn: updateGameStatus,
    refetchOnWindowFocus: true,
    enabled: game.status === 'scheduled',
    refetchInterval: calculateRefetchInterval(new Date(game.startTimestamp)),
  });

  const homeTeamPrimaryColor = game.homeTeam.teamColors.find(
    (tc) => tc.type === 'primary',
  ).hex_color;
  const awayTeamPrimaryColor = game.awayTeam.teamColors.find(
    (tc) => tc.type === 'primary',
  ).hex_color;

  const CardContentElement = (
    <Card
      sx={{
        width: `${cardWidth}px`,
        height: `${cardHeight}px`,
        display: 'flex',
        flexDirection: 'column',
        scrollBehavior: 'smooth', // Enables smooth scrolling
        opacity: isHovered ? 1 : opacity,
        transition: 'opacity 0.3s',
        background: `linear-gradient(-75deg, ${homeTeamPrimaryColor} 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 50%, ${awayTeamPrimaryColor} 100%)`,
        position: 'relative',
        cursor: isClickable ? 'pointer' : 'default',
        pointerEvents: isClickable ? 'auto' : 'none', // Disable pointer events if not clickable
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => {
        if (isClickable) {
          Tracking.getInstance().track('Click Watch Game', {
            category: 'Access & Navigation',
            label: 'Card',
            isLive: gameStatus === 'live',
            game: game.id,
          });
        }
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
          background: 'rgba(0, 0, 0, 0.3)',
          padding: 1,
          position: 'absolute',
          width: '100%',
          height: '100%',
          backdropFilter: 'blur(10px)',
          opacity: isHovered ? 1 : 0,
          transition: 'opacity 0.3s',
        }}
      >
        <Typography sx={{ color: 'white', fontWeight: 'bold', textTransform: 'uppercase' }}>
          {gameStatus === 'live' ? 'Join Game' : 'Watch Game'}
        </Typography>
      </Box>

      <CardContent sx={{ flexGrow: 1, paddingBottom: 0 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography>{formattedStartDate}</Typography>
          {gameStatus === 'live' ? <LiveIndicator color="white" /> : getDuration(start, end)}
          {gameStatus === 'scheduled' && <UpcomingIndicator color={''} />}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
          {[game.awayTeam, game.homeTeam].map((team, idx) => (
            <Box key={team.abbreviation} sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: idx === 0 ? 'row' : 'row-reverse',
                }}
              >
                <Box
                  component="img"
                  src={useTeamLogoPath(team)}
                  alt={team.abbreviation}
                  sx={{ width: 120, height: 120, transition: 'filter 0.3s', ml: 2 }}
                />
                {gameStatus !== 'scheduled' && (
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: 'bold',
                      color:
                        idx === 0
                          ? game.awayTeamScore >= game.homeTeamScore
                            ? 'white'
                            : `rgba(255, 255, 255, 0.6)`
                          : game.homeTeamScore >= game.awayTeamScore
                            ? 'white'
                            : `rgba(255, 255, 255, 0.6)`,
                    }}
                  >
                    {idx === 0 ? game.awayTeamScore : game.homeTeamScore}
                  </Typography>
                )}
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Typography sx={{ fontWeight: 'bold' }}>{team.abbreviation}</Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  );

  return isClickable ? (
    <Link to={`/games/${game.uniqueId}`} style={{ textDecoration: 'none' }}>
      {CardContentElement}
    </Link>
  ) : (
    <Link to={`#`} style={{ textDecoration: 'none' }}>
      {CardContentElement}
    </Link>
  );
};

export default GameCard;
