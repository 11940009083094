import { isEqual, debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';

import Box from '@mui/material/Box';

import useServerStateContext from '@/shared/hooks/useServerStateContext';
import { MANUAL_TRACKING_OR_NO_TRACKING, OBJECT_OF_PLAY } from '@/shared/hooks/useServerState';
import useGameTypeMetadataQuery from '@/shared/hooks/useGameTypeMetadataQuery';

import useRegistrationEntitiesQuery from '../../autoIso/hooks/useAutoIsoEntitiesQuery';

import { AutoIsoModel, AutoIsoObjectType, getTeam } from '../../autoIso/types';
import PtzControls from '../../games/components/PtzControls';
import { useGameState, useGameStateRef } from '@/shared/hooks/websocket/useGameState';
import { ViewType } from '../types/VideoSource';
import { TargetConstants } from '@/shared/types';

import TeamAccordion from '@/features/teams/components/TeamAccordion';
import { setIsActive, sortPlayers } from '@/features/teams/functions';

const AutoIsoSelect = () => {
  // Queries
  const gameMetadataQuery = useGameTypeMetadataQuery();
  const autoIsoQuery = useRegistrationEntitiesQuery();

  // Server State Context
  const {
    trackEntity,
    singleCurrentlyTrackedObjectId: selectedId,
    viewType,
  } = useServerStateContext();

  // Derived Data from Queries
  const { homeTeam, awayTeam, lookup = [], list = [] } = autoIsoQuery?.data || {};

  const selectedEntity = lookup ? lookup[selectedId] : undefined;
  const ballPuck = list.find((e) => e.type === AutoIsoObjectType.BallOrPuck);

  // State Management
  const [initialized, setInitialized] = useState(false);
  const [selectedObjectType, setSelectedObjectType] = useState<AutoIsoObjectType>(
    selectedEntity?.type || AutoIsoObjectType.None,
  );

  const [quadViewSelected, setQuadViewSelected] = useState(viewType === ViewType.MOSAIC);
  const [activeEntities, setActiveEntities] = useState<number[]>([]);
  const [isSelectingPlayer, setSelectingPlayer] = useState(false);

  const [includeInactivePlayers, setIncludeInactivePlayers] = useState(false);
  const [filteredRegistrationEntities, setFilteredRegistrationEntities] = useState(list);
  const [teams, setTeams] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  // Game State
  const [gameState] = useGameState();

  useGameStateRef({
    onChange: (data) => {
      const { entities } = data.tracking;
      const newIds = entities.map((e) => e.id);
      if (!isEqual(newIds, activeEntities)) {
        setActiveEntities(newIds);
      }
    },
  });

  useEffect(() => {
    const filtered = list.filter((entity) => {
      // Always check for active players if inactive players are not included
      if (!includeInactivePlayers && !activeEntities.includes(entity.id)) {
        return false;
      }

      // Check if the search value is empty or if it matches the player's name or jersey number
      const matchesSearchValue =
        searchValue === '' ||
        entity.displayName.toLowerCase().includes(searchValue.toLowerCase()) ||
        (entity.type === AutoIsoObjectType.Player && entity.playerNumber.includes(searchValue));

      return matchesSearchValue;
    });

    setFilteredRegistrationEntities(filtered.map(setIsActive(activeEntities)));
  }, [list, activeEntities, includeInactivePlayers, searchValue]);

  useEffect(() => {
    // Avoid mutating homeTeam and awayTeam directly if they're state
    const updatedTeams = [homeTeam, awayTeam].map((team) => {
      // Group and sort players by their categories
      const categorizedPlayers = filteredRegistrationEntities
        .filter(
          (entity) => entity.type === AutoIsoObjectType.Player && entity?.playerTeamId === team.id,
        )
        .reduce(
          (acc, player) => {
            //@ts-ignore
            const category = player.playerCategory || 'Uncategorized'; // Fallback category
            if (!acc[category]) {
              acc[category] = [];
            }
            acc[category].push(player);
            return acc;
          },
          {} as Record<string, AutoIsoModel[]>,
        );

      // Sort players within each category and apply filtering
      const sortedAndFilteredPlayers = Object.entries(categorizedPlayers).reduce(
        (result, [category, players]) => {
          // Sort players
          const sortedPlayers = players.sort(sortPlayers);

          // Add sorted players to the result
          if (sortedPlayers.length > 0) {
            result[category] = sortedPlayers;
          }

          return result;
        },
        {} as Record<string, AutoIsoModel[]>,
      );

      return { ...team, players: sortedAndFilteredPlayers };
    });

    setTeams(updatedTeams);
  }, [filteredRegistrationEntities, homeTeam, awayTeam]); // Include homeTeam and awayTeam if they change

  useEffect(() => {
    if (viewType === ViewType.MOSAIC) {
      setQuadViewSelected(true);
      if (selectedObjectType === AutoIsoObjectType.None)
        if (ballPuck) trackEntity(OBJECT_OF_PLAY, 'Automatic Switch');
    } else {
      setQuadViewSelected(false);
    }
  }, [viewType, ballPuck]);

  const trackingTargetInAutoIso = useMemo(() => {
    if (!gameState?.tracking?.target) return null;
    const { type, id } = gameState.tracking.target;
    return { type, id }; // Memoize only the relevant properties
  }, [gameState?.tracking?.target?.type, gameState?.tracking?.target?.id]);

  useEffect(() => {
    if (!gameState?.tracking) return;
    const {
      tracking: { target },
    } = gameState;
    if (isSelectingPlayer) return;
    if (target.type === TargetConstants.MANUAL) {
      setSelectedObjectType(AutoIsoObjectType.None);
    }

    if (target.type === TargetConstants.AUTOMATIC) {
      {
        if ('id' in target && target.id === 1) {
          setSelectedObjectType(AutoIsoObjectType.BallOrPuck);
        } else {
          setSelectedObjectType(AutoIsoObjectType.Player);
        }
      }
    }
  }, [trackingTargetInAutoIso, isSelectingPlayer]);

  const debouncedSearch = useCallback(
    debounce((value: string) => {
      setSearchValue(value);
    }, 100),
    [], // Empty dependency array ensures the debounced function is created once
  );

  return (
    <Box mb={2} >
      <Stack flexDirection="row" sx={{ mr: 3, ml: 3, mt: 0 }}>
        <ToggleButtonGroup
          color="primary"
          value={selectedObjectType}
          exclusive
          onChange={(_e, group) => {
            if (group !== null) {
              setSelectedObjectType(group);
            }
          }}
          size="small"
          fullWidth
        >
          <ToggleButton
            value={AutoIsoObjectType.None}
            onClick={(e) => {
              e.preventDefault();
              setSelectingPlayer(false);
              trackEntity(MANUAL_TRACKING_OR_NO_TRACKING, 'Toggle Button');
            }}
            disabled={quadViewSelected}
            size="small"
          >
            Manual
          </ToggleButton>

          <ToggleButton
            value={AutoIsoObjectType.BallOrPuck}
            onClick={(e) => {
              e.preventDefault();
              setSelectingPlayer(false);
              trackEntity(OBJECT_OF_PLAY, 'Toggle Button');
            }}
          >
            {gameMetadataQuery.data?.objectOfPlay || 'Ball/Puck'}
          </ToggleButton>
          <ToggleButton
            value={AutoIsoObjectType.Player}
            onClick={(e) => {
              setSelectingPlayer(true);
            }}
          >
            Player
          </ToggleButton>
        </ToggleButtonGroup>
      </Stack>

      {selectedObjectType === AutoIsoObjectType.Player && (
        <>
          <Stack direction={'column'} sx={{ m: 3 }}>
            <FormGroup>
              <TextField
                label="Search Player"
                variant="outlined"
                fullWidth
                onChange={(e) => debouncedSearch(e.target.value)}
              ></TextField>
              <FormControlLabel
                value={includeInactivePlayers}
                control={<Checkbox />}
                label="Include inactive players"
                onChange={(e) => setIncludeInactivePlayers(!includeInactivePlayers)}
              />
            </FormGroup>
          </Stack>
          <TeamAccordion teams={teams}></TeamAccordion>
        </>
      )}

      {selectedObjectType === AutoIsoObjectType.None && !quadViewSelected ? <PtzControls /> : null}
    </Box>
  );
};

export default AutoIsoSelect;
