import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Tracking } from '@/shared/services/tracking';
import { isUUID } from 'validator';
// features imports
import { AutoIsoId } from '@/features/autoIso/types';
import useCamerasQuery from '@/features/camera/hooks/useCamerasQuery';

// websocket imports
import { useGameStateRef } from './websocket/useGameState';
import { useSelectCamRef } from './websocket/useSelectCam';
import { useSelectMosaicRef } from './websocket/useSelectMosaic';
import { usePTZStore } from './store/usePTZStore';

import useCurrentGameId from './useCurrentGameId';
import { Cameras } from '@/features/camera/types/CameraSettingsState';
import { ServerState, ViewType } from '@/features/camera/types/VideoSource';
import { usePTZSocket } from './websocket/usePTZSocket';
import { useSetTrackingTargetRef } from './websocket/useTrackEntity';
import { TargetConstants } from '../types/GameStateModel';

export const DEFAULT_CAMERA_SETTINGS_STATE: Cameras = [];

export const MANUAL_TRACKING_OR_NO_TRACKING = { id: -1, displayName: 'Manual Tracking', type: 'Manual' };
export const OBJECT_OF_PLAY = { id: 1, displayName: 'Ball/Puck', type: 'BallOrPuck' };

export const MOSAIC_VIEW = ViewType.MOSAIC;

export const DEFAULT_SERVER_STATE: ServerState = {
  cameras: [],
  selectedCamera: 0,
  viewType: ViewType.MOSAIC,
};

const handleTrackingEntityUpdated = (currentState: Cameras, trackingId: AutoIsoId): Cameras => {
  currentState.forEach((camera) => {
    camera.trackingId = trackingId;
  });
  return currentState;
};

const ViewSourceToViewType = (source: string): ViewType => {
  switch (source) {
    case 'mosaic':
      return ViewType.MOSAIC;
    case 'autoTally':
      return ViewType.AUTO_TALLY;
    case 'single':
      return ViewType.SINGLE_CAMERA;
    default:
      return ViewType.SINGLE_CAMERA;
  }
};

/**
 * Supplies state tracking on top of the {@link useServerConnection} hook.
 * @returns functions for altering the server's state
 */
const useServerState = () => {
  const gameId = useCurrentGameId();
  const [singleCurrentlyTrackedObjectId, setSingleCurrentlyTrackedObjectId] = useState<number>(MANUAL_TRACKING_OR_NO_TRACKING.id);
  // Since the API currently doesn't provide a way for us to know what video source the
  // server is starting with when the app loads, we need to manage the cameras query here.
  const cameraSettingsStateQuery = useCamerasQuery();
  const [serverState, setServerState] = useState<ServerState>(DEFAULT_SERVER_STATE);

  //websocket operations
  const [, setSelectCam] = useSelectCamRef();
  const [, setSelectMosaic] = useSelectMosaicRef();
  const [, setTrackingTarget] = useSetTrackingTargetRef();

  const { init: initPTZStore } = usePTZStore();
  const { reset: resetCameraPositions, setValue: enterManualMode } = usePTZSocket();

  // handleChange on the State
  const { handleChange: updatePTZstore } = usePTZStore();

  useEffect(() => {
    cameraSettingsStateQuery.refetch();
  }, []);


  useGameStateRef({
    onChange: (data) => {
      const { tracking, view, vcam } = data;
      if (!tracking) return;

      const { target } = tracking;
      const { type, idx: cameraIndex } = view;

      const targetId = target.type === TargetConstants.AUTOMATIC && 'id' in target ? target.id : MANUAL_TRACKING_OR_NO_TRACKING.id;

      setSingleCurrentlyTrackedObjectId((currentId) => (currentId !== targetId ? targetId : currentId));

      if (target.type === TargetConstants.AUTOMATIC && 'id' in target) {
        setServerState((currentState) => {
          const updatedCameras = handleTrackingEntityUpdated(currentState.cameras, target.id);
          return JSON.stringify(currentState.cameras) !== JSON.stringify(updatedCameras)
            ? { ...currentState, cameras: updatedCameras }
            : currentState;
        });
      }

      const selectedIdx = typeof cameraIndex === 'number' ? cameraIndex : Number.parseInt(cameraIndex, 10);

      if (vcam) {
        updatePTZstore(vcam, selectedIdx);
      }

      setServerState((currentState) => {
        const newState = {
          ...currentState,
          selectedCamera: selectedIdx,
          viewType: ViewSourceToViewType(type),
        };

        return JSON.stringify(currentState) !== JSON.stringify(newState) ? newState : currentState;
      });
    }
  })

  const selectMosaic = useCallback((source: string) => {
    setSelectMosaic({ dims: { width: 0, height: 0 }, idxs: [] });

    Tracking.getInstance().track('Switch View', {
      source,
      category: 'Controls',
      type: 'mosaic',
    });
  },[]);

  const selectCamera = useCallback((idx: number, source: string) => {
    setSelectCam(idx);

    Tracking.getInstance().track('Switch View', {
      source,
      category: 'Controls',
      type: 'single',
    });

  },[]);

  const trackEntity = useCallback((ent: any, receivedSource?: string) => {

    let source: string = receivedSource;

    if (!ent) throw new Error('Entity must be defined');
    if (!ent.id) throw new Error('Entity must have an id');

    const { id } = ent;

    if (ent.id === MANUAL_TRACKING_OR_NO_TRACKING.id) {
      source = 'Manual Tracking';
      enterManualMode({ pan: 0, tilt: 0, zoom: 0 });
    } else {
      setTrackingTarget(id);
    }


    if (source) {
      Tracking.getInstance().track('Switch Tracked Entity', {
        category: 'Iso',
        source,
        ent,
      });
    }
  },[]);



  useEffect(() => {
    if (
      gameId && 
      isUUID(gameId) &&
      cameraSettingsStateQuery.status === 'success' &&
      cameraSettingsStateQuery.data.data
    ) {
      initPTZStore(cameraSettingsStateQuery.data.data);

      resetCameraPositions();

      setServerState((currentState) => ({
        ...currentState,
        cameras: cameraSettingsStateQuery.data.data || DEFAULT_CAMERA_SETTINGS_STATE,
      }));
    }
  }, [cameraSettingsStateQuery.data, gameId]);

  const serverStateAndFunctions = {
    availableCameras: serverState.cameras, //ok 
    trackEntity,
    selectCamera,
    selectMosaic,
    singleCurrentlyTrackedObjectId, //ok
    selectedCameraId: serverState.selectedCamera, // ok
    selectedCamera: serverState.cameras[serverState.selectedCamera], // ok
    viewType: serverState.viewType,// ok
  };

  const serverStateAndFunctionsCopyRef = useRef(serverStateAndFunctions);

  useEffect(() => {
    if (JSON.stringify(serverStateAndFunctionsCopyRef.current) !== JSON.stringify(serverStateAndFunctions)) {
      serverStateAndFunctionsCopyRef.current = serverStateAndFunctions;
    }
  }, [serverStateAndFunctions]);

  const getServerStateAndFunctions = useCallback(() => serverStateAndFunctionsCopyRef.current, []);

  return { ...serverStateAndFunctions, getServerStateAndFunctions };
};

export type ServerStateAndFunctions = ReturnType<typeof useServerState>;
export type ServerStateAndFunctionWithoutGetter = Omit<
  ReturnType<typeof useServerState>,
  'getServerStateAndFunctions'
>;

export default useServerState;
