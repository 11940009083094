

import { useMutation } from '@tanstack/react-query';
import { post } from '@truefit/http-utils';

export type InvitePayload = {
    groupId: number;
    email: string;
}


const inviteUserToOrganizationMutation = () => {

    return useMutation({
        mutationFn: async (model: InvitePayload): Promise<void> => {
            await post<void>(`/users/invite`, model);
        }
    });
};

export default inviteUserToOrganizationMutation;
