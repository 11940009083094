import Tracking from '@/shared/services/tracking';

const identifyUser = (username,email, ldClient, group) => {
  try {
    Tracking.getInstance().identify(username, { email, group });
    ldClient.identify({ email, key: username, custom: { group } });
  } catch (e) {
    console.error(e);
    throw new Error('Unable to identify user');
  }
};

export default identifyUser;
