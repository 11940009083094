import { useQuery } from '@tanstack/react-query';
import { get } from '@truefit/http-utils';

export const queryKey = (gameId: string) => ['SwitchGame', gameId];

const useGameSwitchQuery = (gameId: string) =>
  useQuery({
    queryKey: queryKey(gameId),
    queryFn: async () => get(`/games/${gameId}/switch`),
    gcTime: 0,
    enabled: false,
    refetchOnWindowFocus: false,
  });

export default useGameSwitchQuery;
