import { useHotkeys } from 'react-hotkeys-hook';

import { KeyboardShortcuts } from '@/shared/constants/keyboardShortcuts';
import { CameraPtzAdjustment } from '../types/CameraPtzAdjustment';


const useCameraPtzKeyboardBindings = (onPtzChange: (adjustment: CameraPtzAdjustment) => void) => {
  const bindings = [
    {
      hotkey: KeyboardShortcuts.TiltUp.hotkey,
      onKeyDown: () => onPtzChange({ tiltDiff: 1 }),
      onKeyUp: () => onPtzChange({ tiltDiff: 0 }),
    },
    {
      hotkey: KeyboardShortcuts.TiltDown.hotkey,
      onKeyDown: () => onPtzChange({ tiltDiff: -1 }),
      onKeyUp: () => onPtzChange({ tiltDiff: 0 }),
    },
    {
      hotkey: KeyboardShortcuts.PanLeft.hotkey,
      onKeyDown: () => onPtzChange({ panDiff: -1 }),
      onKeyUp: () => onPtzChange({ panDiff: 0 }),
    },
    {
      hotkey: KeyboardShortcuts.PanRight.hotkey,
      onKeyDown: () => onPtzChange({ panDiff: 1 }),
      onKeyUp: () => onPtzChange({ panDiff: 0 }),
    },
    {
      hotkey: KeyboardShortcuts.ZoomIn.hotkey,
      onKeyDown: () => onPtzChange({ zoomDiff: -1 }),
      onKeyUp: () => onPtzChange({ zoomDiff: 0 }),
    },
    {
      hotkey: KeyboardShortcuts.ZoomOut.hotkey,
      onKeyDown: () => onPtzChange({ zoomDiff: 1 }),
      onKeyUp: () => onPtzChange({ zoomDiff: 0 }),
    },
  ];

  bindings.forEach(({ hotkey, onKeyDown, onKeyUp }) => {
    useHotkeys(hotkey, (e) => {
      e.preventDefault();
      onKeyDown();
    }, { keydown: true }, [onPtzChange]);

    useHotkeys(hotkey, (e) => {
      e.preventDefault();
      onKeyUp();
    }, { keyup: true }, [onPtzChange]);
  });
};

export default useCameraPtzKeyboardBindings;