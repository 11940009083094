import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";

export const LiveIndicator = ({ color }: { color: string }) => (
  <Box
    sx={{
      px: 1,
      color,
      backgroundColor: '#e74c3c',
      border: '1px solid white',
      borderRadius: 1.5,
      p: 0.5,
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      animation: 'pulse 1.5s ease-in-out infinite', // Applies pulse animation
      '@keyframes pulse': {
        '0%': {
          transform: 'scale(1)',
          opacity: 1,
        },
        '50%': {
          transform: 'scale(1.1)',
          opacity: 0.8,
        },
        '100%': {
          transform: 'scale(1)',
          opacity: 1,
        },
      },
    }}
  >
    <Typography
      sx={{
        lineHeight: 1.25,
        fontSize: 12,
        textTransform: 'capitalize',
      }}
    >
      Live
    </Typography>
  </Box>
);