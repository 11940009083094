import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';

import { Search } from '@mui/icons-material';
import { Box, Container, Divider, Stack, TextField, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

import useAllClipsQuery, { getQueryKey } from '../hooks/useAllClipsQuery';
import ClipsForm, { DEFAULT_VALUES, FormValues as ClipsFormValues } from './ClipsForm';
import ClipsTable from './ClipsTable';
import { useDocumentTitle } from '@/shared/hooks/useDocumentTitle';
import { AppPage } from '@/shared/components';

const ClipsPage = () => {
  const queryClient = useQueryClient();

  const [formValues, setFormValues] = useState<ClipsFormValues>(DEFAULT_VALUES);
  const [textSearch, setTextSearch] = useState('');
  const [pageSize, setPageSize] = useState(5);
  const [page, setPage] = useState(0);

  const clipsQuery = useAllClipsQuery(formValues, textSearch, pageSize, page);

  const total = clipsQuery.data?.total || 0;
  const rows = clipsQuery.data?.results || [];
  const { isLoading } = clipsQuery;
  const title = 'Bookmarks';
  const handleFilterAndSortChange = (values: ClipsFormValues) => {
    setFormValues(values);
  };

  useDocumentTitle(title);

  useEffect(() => {
    queryClient.invalidateQueries(getQueryKey(formValues));
    clipsQuery.refetch();
  }, [textSearch, page, queryClient, formValues, clipsQuery]);

  const handleTextInputChange = debounce((event) => {
    setTextSearch(event.target.value);
  }, 500);

  return (
    <AppPage title={title}>
      <Container maxWidth="xl" sx={{ marginTop: 4, marginBottom: 4 }}>
        <Typography variant="h6">Select a Bookmark or Clip</Typography>
        <Typography variant="body1">
          View bookmarks and clips that have been created in your deparment
        </Typography>
        <Divider sx={{ marginTop: 3 }} />
        <ClipsForm onChange={handleFilterAndSortChange} />

        <Stack spacing={2}>
          {clipsQuery.isError ? (
            <Typography color="error" sx={{ textAlign: 'center' }}>
              An error ocurred while fetching clips.
            </Typography>
          ) : null}

          {total !== undefined ? (
            <Typography variant="h6" sx={{ marginTop: 2 }}>
              {total} Records
            </Typography>
          ) : null}
          <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
            <Search sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
            <TextField
              id="input-with-sx"
              label="Search"
              variant="standard"
              onChange={handleTextInputChange}
              style={{ width: '300px' }}
            />
          </Box>
          <ClipsTable
            rows={rows}
            loading={isLoading}
            setPage={setPage}
            page={page}
            setPageSize={setPageSize}
            pageSize={pageSize}
            rowCount={total}
          />
        </Stack>
      </Container>
    </AppPage>
  );
};

export default ClipsPage;
