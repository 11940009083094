import { useTheme } from '@mui/material';
import { AutoIsoObjectType } from '../types/AutoIsoModel';
import { makeStyles } from '@mui/styles';
import useServerStateContext from '@/shared/hooks/useServerStateContext';
import { useMemo } from 'react';

function roundUpToNDecimalPlaces(num: number, decimalPlaces: number): number {
  const multiplier = 10 ** decimalPlaces;
  return Math.ceil(num * multiplier) / multiplier;
}

function getContrastColor(hex: string): string {
  // Remove the '#' if it's included
  hex = hex.replace('#', '');

  // Parse the hex color to RGB
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Calculate the luminance of the color
  const luminance = 0.2126 * (r / 255) + 0.7152 * (g / 255) + 0.0722 * (b / 255);

  // Return white or black based on luminance threshold
  return luminance > 0.5 ? 'black' : 'white';
}

const useStyles = makeStyles(() => ({
  obj: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    color: 'black',
  },
}));

export const TrackedEntity = ({
  isFlipped,
  awayTeam,
  elementSize,
  entity,
  objectOfPlay,
  pos,
  homeTeam,
  setTop,
  surfaceHeight,
  surfaceWidth,
  top,
}) => {
  const { singleCurrentlyTrackedObjectId, trackEntity } = useServerStateContext();
  const isTracked = entity.id.toString() === singleCurrentlyTrackedObjectId;
  const theme = useTheme();
  const styles = useStyles();

  const label = useMemo(() =>
      entity.type === AutoIsoObjectType.Player || entity.type === AutoIsoObjectType.Official
        ? entity.playerNumber
        : objectOfPlay?.charAt(0).toUpperCase(),
    [objectOfPlay,entity.playerNumber,entity.type],
  );

  const objectSize = useMemo(() => {
    return entity.type === AutoIsoObjectType.Player || entity.type === AutoIsoObjectType.Official
      ? 24
      : 15;
  }, [entity.type]);

  const backgroundColor = useMemo(() => {
    const onHomeTeam =
      entity.type === AutoIsoObjectType.Player && entity.playerTeamId === homeTeam.id;
      if (entity.id === singleCurrentlyTrackedObjectId) return '#ffffff';
      if (entity.type === AutoIsoObjectType.Player)
      return onHomeTeam
        ? homeTeam.teamColors[0].hex_color
        : awayTeam.teamColors[0].hex_color;
    return entity.type === AutoIsoObjectType.Official
      ? theme.palette.grey[700]
      : theme.palette.primary.main;
  }, [entity, homeTeam, awayTeam, singleCurrentlyTrackedObjectId, theme.palette]);


  const color = useMemo(() => {
    return getContrastColor(backgroundColor);
  },[backgroundColor]);

  const flipCoef = isFlipped ? -1 : 1;
  const objectLabelFontSize = objectSize / 2;
  const xPercent = (pos.x * flipCoef / (surfaceWidth / 2) + 1) / 2.0;
  const yPercent = (pos.y * flipCoef/ (surfaceHeight / 2) + 1) / 2.0;
  const xVal = (elementSize.width - objectSize) * xPercent ;
  const yVal = (elementSize.height - objectSize) * yPercent;
  const zIndex = entity.id===1 ? 10000 : (isTracked || top === entity.id) ? 1000 :  entity.playerNumber;

  return (
    <div
      className={styles.obj}
      style={{
        cursor: 'pointer',
        width: objectSize,
        height: objectSize,
        borderRadius: objectSize / 2,
        backgroundColor,
        left: roundUpToNDecimalPlaces(xVal,0),
        bottom: roundUpToNDecimalPlaces(yVal,0),
        fontSize: objectLabelFontSize,
        color: color,
        border: '1px solid black',
        zIndex,
        boxShadow: '0px 0px 5px 5px #00000022',
        opacity: isTracked || top === entity.id ? 1 : 0.8,
      }}
      onMouseDown={(e) => {
        e.stopPropagation();
        trackEntity(entity, `Virtual surface`);
      }}
      onMouseOver={() => setTop(entity.id)}
      key={pos.id}
    >
      {label}
    </div>
  );
};
