import { enableLoggingTracker } from '@/shared/constants';

import { Tracker } from './Tracker';

const capitalize = (s) =>
  s[0].toUpperCase() + s.slice(1).replace(/-./g, (x) => ` ${x[1].toUpperCase()}`);

export class Logger implements Tracker {
  private static instance: Logger;

  private ready = false;

  private name = 'Logger';

  private constructor() {
    if (!enableLoggingTracker) {
      console.debug('Skipped Dummy Logger');
      return;
    }
    this.ready = true;
    if (this.ready) console.debug('Loaded Dummy Logger for analytics');
  }

  signIn(props): void {
    this.track('sign-in', props);
  }

  signOut(props): void {
    this.track('sign-out', props);
  }

  public toString() {
    return this.name;
  }

  public static getInstance(): Logger {
    if (!Logger.instance) {
      Logger.instance = new Logger();
    }

    return Logger.instance;
  }

  public identify(userId: string, userProps: any) {
    const userprops = JSON.parse(JSON.stringify(userProps));
    try {
      if (!this.ready) return;
      userprops.userId = userId;
      this.log('identified', userprops);
    } catch (error) {
      console.error(error);
    }
  }

  public navigation(location, props) {
    if (!this.ready) return;
    this.log('navigation', { category: 'Navigation', location, ...props });
  }

  public track(event: string, payload?: any) {
    if (!this.ready) return;
    this.log(capitalize(event), payload);
  }

  private log(event, inputprops) {
    const props = JSON.parse(JSON.stringify(inputprops));
    props.datetime = new Date().toISOString();
    props.event = event;
    props.tracker = this.name;
  }
}
