import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useCurrentGameId from '@/shared/hooks/useCurrentGameId';
import useServerStateContext from '@/shared/hooks/useServerStateContext';
import useRegistrationsQuery from '../hooks/useAutoIsoEntitiesQuery';
import useGameTypeMetadataQuery from '@/shared/hooks/useGameTypeMetadataQuery';
import { useGameState } from '@/shared/hooks/websocket/useGameState';
import { getBusinessApiEndPoint } from '../../../util';
import { useSurfaceDimensions } from './useSurfaceDimensions';
import { TrackedEntity } from './TrackedEntity';
import { CameraIcon, cameraSize } from './CameraIcon';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { AutoIsoModel } from '../types/AutoIsoModel';
import { isEqual } from 'lodash';

type Props = {
  elementSize: { width: number; height: number };
  refreshRate?: number; // Refresh rate in frames per second
  isFlipped: boolean;
};

const GamePositionsRepresentation = ({ elementSize, isFlipped }: Props) => {
  const currentGameId = useCurrentGameId();
  const { availableCameras, selectedCameraId, selectCamera } = useServerStateContext();
  const { showCameraIcons } = useFlags();
  const registrationsQuery = useRegistrationsQuery();
  const { lookup: registrationsLookup, homeTeam, awayTeam } = registrationsQuery?.data || {};
  const theme = useTheme();
  const gameTypeMetadataQuery = useGameTypeMetadataQuery();
  const { surfaceWidth, surfaceHeight, surfaceName, objectOfPlay } =
    useSurfaceDimensions(gameTypeMetadataQuery);
  const [top, setTop] = useState<string>();
  const [trackedEntities, setTrackedEntities] = useState<AutoIsoModel[]>([]);
  const prevEntitiesRef = useRef<AutoIsoModel[]>([]);
  const surfaceImageUrl = `${getBusinessApiEndPoint('surface')}/games/${currentGameId}/surface`;
  const [gameState] = useGameState();

  const trackingEntities = useMemo(() => {
    return gameState?.tracking.entities ? [...gameState.tracking.entities] : undefined;
  }, [gameState?.tracking.entities]);

  useEffect(() => {
    if (!gameState) return;
    let updatedEntities = [...gameState.tracking.entities];
    updatedEntities = updatedEntities
      .map((autoIsoEntity) => {
        const entity = registrationsLookup[autoIsoEntity.id];
        if (!entity) return null;
        return { ...entity, ...autoIsoEntity };
      })
      .filter(Boolean);
    // Only update if entities actually changed
    if (!isEqual(updatedEntities, prevEntitiesRef.current)) {
      setTrackedEntities(updatedEntities);
      prevEntitiesRef.current = updatedEntities;
    }
  }, [trackingEntities]);

  const cameraPositions = useMemo(() => {
    return availableCameras
      .filter((c) => Number.isFinite(c.x) && Number.isFinite(c.y))
      .map((camera) => {
        const flipCoef = isFlipped ? -1 : 1;

        const xPercent = (camera.x * flipCoef / (surfaceWidth / 2) + 1) / 2.0;
        const yPercent = (camera.y * flipCoef / (surfaceHeight / 2) + 1) / 2.0;
        const xVal = (elementSize.width - cameraSize) * xPercent;
        const yVal = (elementSize.height - cameraSize) * yPercent;
        const computedX = Number.isFinite(xVal) ? xVal : 0;
        const computedY = Number.isFinite(yVal) ? yVal : 0;

        return {
          ...camera,
          computedX,
          computedY,
        };
      });
  }, [availableCameras, elementSize, surfaceWidth, surfaceHeight]);

  return (
    <Box
      sx={{
        touchAction: 'none',
        userSelect: 'none',
        width: elementSize.width,
        height: elementSize.height,
        position: 'relative',
        transition: 'transform 0.3s ease', // Smooth transition
      }}
    >

      <img
        alt={surfaceName}
        src={surfaceImageUrl}
        style={{ width: '100%', position: 'absolute', transform: isFlipped ? 'scaleY(-1)' : 'none' }}
      />

      {showCameraIcons &&
        cameraPositions.map((camera) => (
          <CameraIcon
            isFlipped={isFlipped}
            key={camera.id}
            camera={camera}
            xVal={camera.computedX}
            yVal={camera.computedY}
            theme={theme}
            isActive={selectedCameraId === camera.idx}
            onClick={() => selectCamera(camera.idx, `Virtual ${surfaceName}`)}
          />
        ))}

      {trackedEntities &&
        trackedEntities.map((entity) => (
          <TrackedEntity
            isFlipped={isFlipped}
            key={entity.id}
            entity={entity}
            pos={entity.pos}
            elementSize={elementSize}
            surfaceWidth={surfaceWidth}
            surfaceHeight={surfaceHeight}
            objectOfPlay={objectOfPlay}
            awayTeam={awayTeam}
            homeTeam={homeTeam}
            setTop={setTop}
            top={top}
          />
        ))}
    </Box>
  );
};

export default GamePositionsRepresentation;