import { useRef, useEffect } from 'react';
import throttle from 'lodash/throttle';

import useWebSocket from './useWebSocket';
import { WebSocketRefHook, withStateFromRef } from '../withStateFromRef';
import { GameState } from '@/shared/types/GameStateModel';

const FPS = 24;

export const useGameStateRef: WebSocketRefHook<GameState> = (options = {}) => {

  const ref = useRef<GameState | null>(null);
  const throttledHandler = useRef(throttle((message: GameState) => {

    // Filter out entities that are not in the field of play
    message.tracking.entities = message.tracking?.entities?.filter((entity) => entity.pos.y < 42 && entity.pos.y > -42 && entity.pos.z > 0);
    
    ref.current = message;
    if (options.onChange) {
      
      options.onChange(message);
    }
  }, 1000/FPS)); // Throttle interval in milliseconds

  useEffect(() => {

    return () => {
      throttledHandler.current.cancel();
    };
  }, []);

  useWebSocket<GameState>('/state/0.1.0', {
    onMessage: (message) => {
      throttledHandler.current(message);
    },
  });



  return [ref];
};

export const useGameState = withStateFromRef(useGameStateRef,'useGameState');