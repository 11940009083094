import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useContext, useEffect } from 'react';

import { CognitoContext } from '../components/CognitoProvider';
import { CognitoState } from '../types';

import authenticateUser from '../functions/authenticateUser';
import identifyUser from '../functions/identifyUser';
import completeNewPasswordChallenge from '../functions/completeNewPasswordChallenge';
import confirmPassword from '../functions/confirmPassword';
import forgotPassword from '../functions/forgotPassword';
import getSession from '../functions/getSession';
import signOut from '../functions/signOut';
import setHttpAuth from '../functions/setHttpAuth';
import { cognitoClientId, cognitoPoolId } from '@/shared/constants';
import changePassword from '../functions/changePassword';

const useCognito = () => {
  const { cognitoContext, setCognitoContext } = useContext(CognitoContext);

  const ldClient = useLDClient();

  const userPool = new CognitoUserPool({
    UserPoolId: cognitoPoolId,
    ClientId: cognitoClientId,
  });

  const updateCognitoContext = (updates: Partial<CognitoState>) => {
    setCognitoContext({ ...cognitoContext, ...updates });
  };

  //useEffect(() => {
  //  if (cognitoContext.authenticated) {
  //    identifyUser(cognitoContext, ldClient);
  //  }
  //}, []);

  return {
    ...cognitoContext,
    updateCognitoContext,
    authenticateUser: authenticateUser(updateCognitoContext, userPool),
    completeNewPasswordChallenge: completeNewPasswordChallenge(
      cognitoContext.user,
      updateCognitoContext,
    ),
    setHttpAuth,
    changePassword: changePassword(cognitoContext.user),
    confirmPassword: confirmPassword(cognitoContext.user),
    forgotPassword: forgotPassword(updateCognitoContext, userPool),
    getSession: getSession(updateCognitoContext, userPool),
    signOut: signOut(cognitoContext.user, updateCognitoContext),
  };
};

export default useCognito;
