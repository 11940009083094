import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { nameofFactory } from '@/shared/services';
import { Tracking } from '@/shared/services/tracking';

import {
  FormSubmitBar,
  FormValidationSummary,
  HookFormPasswordField,
  HookFormTextField,
} from '../../forms/components';
import useCognito from '../hooks/useCognito';
import { useDocumentTitle } from '@/shared/hooks/useDocumentTitle';
import { FormControl, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import { getOrgsWithAccess } from '@/shared/services/getOrgsWithAccess';
import useCurrentCloudUser, { User } from '@/shared/hooks/user/getCurrentUser';
import { Org } from '@/types/user';
import useUpdateSession from '@/shared/hooks/user/useUpdateSession';

type SignInFormValues = {
  username: string;
  password: string;
};

const nameOf = nameofFactory<SignInFormValues>();

const validationSchema = Yup.object().shape({
  [nameOf('username')]: Yup.string().required().max(256).label('Username'),
  [nameOf('password')]: Yup.string().required().max(50).label('Password'),
});

const useSignIn = () => {
  const cognito = useCognito();
  const queryString = new URLSearchParams(useLocation().search);
  const returnUrl = queryString.get('returnUrl');
  const history = useHistory();
  const [availableOrganizations, setAvailableOrganizations] = useState<Org[]>([]);

  const currentCloudUserQuery = useCurrentCloudUser();

  const form = useForm<SignInFormValues>({
    defaultValues: {
      [nameOf('username')]: '',
      [nameOf('password')]: '',
    },
    resolver: yupResolver(validationSchema),
  });

  const [generalValidationErrors, setGeneralValidationErrors] = React.useState<string[]>([]);

  useEffect(() => {
    if (currentCloudUserQuery.status === 'success') {
      const {
        data: {
          data: { user },
        },
      } = currentCloudUserQuery;

      const orgWithAccess = getOrgsWithAccess(user);
      if (orgWithAccess.length > 0) {
        setAvailableOrganizations(orgWithAccess);
      } else {
        setAvailableOrganizations([]);
        Tracking.getInstance().signIn();
        history.push(returnUrl ?? '/');
      }
    }
  }, [currentCloudUserQuery.status]);

  const handleSubmit = form.handleSubmit(
    (formValues: SignInFormValues) =>
      new Promise<void>((resolve) => {
        const { username, password } = formValues;

        cognito.authenticateUser({
          username,
          password,

          onSuccess() {
            currentCloudUserQuery.refetch();

            resolve();
          },

          onFailure(message) {
            setGeneralValidationErrors([message]);
            resolve();
          },

          newPasswordRequired() {
            Tracking.getInstance().track('Reset Password', {
              categort: 'Auth',
            });

            history.push('/reset-password');
            resolve();
          },
        });
      }),
  );

  return {
    availableOrganizations,
    cognito,
    form,
    generalValidationErrors,
    handleSubmit,
    history,
    returnUrl,
    userOrganization: currentCloudUserQuery.data?.data?.org?.id || -1,
  };
};

const SignInForm = () => {
  const {
    availableOrganizations,
    cognito,
    form,
    generalValidationErrors,
    handleSubmit,
    history,
    returnUrl,
    userOrganization,
  } = useSignIn();
  const useUpdateSessionMutation = useUpdateSession();

  const [organization, setOrganization] = useState(userOrganization);

  const { isSubmitting } = form.formState;
  const disabled = isSubmitting;

  useEffect(() => {
    setOrganization(userOrganization);
  }, [userOrganization]);

  useDocumentTitle(`Log In`);

  const continueLogin = async () => {
    if (organization !== -1) {
      const org = availableOrganizations.find((o) => o.id === organization);
      if (!org) {
        return;
      }
      await useUpdateSessionMutation.mutateAsync({
        org: org.id,
        league: org.leagues[0].id,
      });
      history.push(returnUrl ?? '/');
    }
  };

  return (
    <FormProvider {...form}>
      <form noValidate style={{ width: '100%' }}>
        <FormValidationSummary validationErrors={generalValidationErrors} />

        {availableOrganizations.length > 1 && cognito.authenticated ? (
          // If organizations are available, only show the organization dropdown
          <FormControl fullWidth>
            <InputLabel id="organization-select-label">Organization</InputLabel>
            <Select
              labelId="organization-select-label"
              id="organization-select"
              value={organization}
              label="Organization"
              onChange={(event) => setOrganization(event.target.value)}
            >
              {availableOrganizations.map((org) => (
                <MenuItem key={org.id} value={org.id}>
                  {org.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          // If no organizations, show username and password fields
          <>
            <HookFormTextField
              name="username"
              autoFocus
              required
              label="Email"
              disabled={disabled}
              variant="outlined"
              margin="dense"
            />

            <HookFormPasswordField
              name="password"
              required
              label="Password"
              disabled={disabled}
              variant="outlined"
              margin="dense"
              onEnter={handleSubmit}
            />
          </>
        )}
        {availableOrganizations.length > 0 && cognito.authenticated ? (
          <Stack spacing={2} padding={2} direction="row" justifyContent="center">
            <Button variant="contained" color="primary" onClick={continueLogin}>
              Continue
            </Button>
          </Stack>
        ) : (
          <FormSubmitBar
            submitting={isSubmitting}
            buttonText="Sign In"
            onSubmit={handleSubmit}
            mainButtonStyle={{ width: '50%' }}
          />
        )}
        {availableOrganizations.length === 0 && !cognito.authenticated && (
          <Grid container justifyContent="center" alignItems="center">
            <Button
              component={RouterLink}
              to="/forgot-password"
              color="secondary"
              sx={{ mt: 1, ml: -1 }}
            >
              Forgot password?
            </Button>
          </Grid>
        )}
      </form>
    </FormProvider>
  );
};

export default SignInForm;
