import { addSeconds, differenceInMilliseconds, subMilliseconds } from 'date-fns';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { KeyboardShortcut, KeyboardShortcuts } from '@/shared/constants';
import useClipStateContext from '@/shared/hooks/useClipStateContext';
import useCurrentGameId from '@/shared/hooks/useCurrentGameId';
import useKeyboardShortcutsDialog from '@/shared/hooks/useKeyboardShortcutsDialog';
import useServerStateContext from '@/shared/hooks/useServerStateContext';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import FastForwardRoundedIcon from '@mui/icons-material/FastForwardRounded';
import FastRewindRoundedIcon from '@mui/icons-material/FastRewindRounded';
import FullscreenExitRoundedIcon from '@mui/icons-material/FullscreenExitRounded';
import FullscreenRoundedIcon from '@mui/icons-material/FullscreenRounded';
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import PauseRoundedIcon from '@mui/icons-material/PauseRounded';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import { Tooltip as BaseTooltip, Divider, Stack, TooltipProps, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { useFlags } from 'launchdarkly-react-client-sdk';

import useJumpFrame from '@/shared/hooks/websocket/useJumpFrame';
import { usePlaybackSpeedState } from '@/shared/hooks/websocket/usePlaybackSpeed';
import { useTimestampState } from '@/shared/hooks/websocket/useTimestamp';

import { displayInterval } from '@/shared/services';
import displayWallClock from '@/shared/services/displayWallClock';
import { Tracking } from '@/shared/services/tracking';

import useGameDetailsQuery from '../../games/hooks/useGameDetailsQuery';
import usePlayerHotkeys from '../hooks/usePlayerHotkeys';
import OneFrameBackward from './OneFrameBackwardIcon';
import OneFrameForward from './OneFrameForwardIcon';
import OneSecondBackward from './OneSecondBackwardIcon';
import OneSecondForward from './OneSecondForwardIcon';
import PlaybackPositionSlider from './PlaybackPositionSlider';
import PlaybackSpeedSelectionButton from './PlaybackSpeedSelectionButton';
import PlayerCameraFeedSelectionButton from './PlayerCameraFeedSelectionButton';

import { useGameState } from '@/shared/hooks/websocket/useGameState';
import { ViewType } from '@/features/camera/types/VideoSource';

export type GamePlayBackPositions = {
  start: number;
  head: number;
  live: number;
};

type Props = {
  exitFullscreen: () => Promise<void>;
  isFullscreen: boolean;
  requestFullscreen: () => Promise<void>;
  onStatsToggle?: () => unknown;
};

type PlayerControlProps = {
  children: React.ReactNode;
  color?: string;
  disabled?: boolean;
  onClick: () => unknown;
  title: string;
};

const Tooltip = (props: TooltipProps) => (
  <BaseTooltip arrow placement="top" PopperProps={{ disablePortal: true }} {...props} />
);

const PRIMARY_COLOR = '#ffffff';
const SECONDARY_COLOR = 'b8b8b8';

const PlayerControl = ({
  children,
  color = PRIMARY_COLOR,
  disabled = false,
  onClick,
  title,
}: PlayerControlProps) => (
  <Box>
    <Tooltip title={title} arrow>
      <IconButton size="small" onClick={onClick} sx={{ color }} disabled={disabled}>
        {children}
      </IconButton>
    </Tooltip>
  </Box>
);

const displayCommand = (shortcut: KeyboardShortcut) => `${shortcut.command} (${shortcut.hotkey})`;

const PlayerControls = ({
  requestFullscreen,
  exitFullscreen,
  isFullscreen,
  onStatsToggle = () => {},
}: Props) => {
  const [showingBookmarks, setShowingBookmarks] = useState(true);
  const [, setTimestamp] = useTimestampState();

  const gameId = useCurrentGameId();
  const gameQuery = useGameDetailsQuery(gameId);

  const [gameState] = useGameState();
  const [playbackSpeed, setPlaybackSpeed] = usePlaybackSpeedState();
  const [isPlaying, setIsPlaying] = useState(playbackSpeed !== 0);

  const [gamePlaybackPositions, setGamePlaybackPositions] = useState<GamePlayBackPositions>({
    head: 0,
    live: 0,
    start: 0,
  });

  const [isLiveEdge, setIsLiveEdge] = useState(false);
  const { selectMosaic, viewType } = useServerStateContext();
  const { jumpFrame } = useJumpFrame();
  const { livePlaybackDelay, liveTimestampOffset, ffAndRw } = useFlags();

  useEffect(() => {
    if (gameQuery.status === 'success') {
      setGamePlaybackPositions({
        ...gamePlaybackPositions,
        start: gameQuery.data.startTimestamp.getTime(),
      });
    }
  }, [gameQuery.status]);

  useEffect(() => {
    setIsPlaying(gameState?.speed === 1);
  }, [gameState?.speed]);

  useEffect(() => {
    if (gameState?.head) {
      setGamePlaybackPositions({
        ...gamePlaybackPositions,
        head: gameState.head / 1_000_000, // nano to milli
        live: gameState.live / 1_000_000, // nano to milli
      });
    }
  }, [gameState?.head]);

  const playbackPositionSeconds = Math.round(
    differenceInMilliseconds(gamePlaybackPositions.head, gamePlaybackPositions.start) / 1000,
  );
  const endPositionSeconds = Math.round(
    differenceInMilliseconds(gamePlaybackPositions.live, gamePlaybackPositions.start) / 1000,
  );

  const {
    state: { isEditing },
  } = useClipStateContext();

  const durationLabel = (seconds: number) => {
    try {
      if (gamePlaybackPositions.start)
        return displayInterval({
          start: gamePlaybackPositions.start,
          end: addSeconds(gamePlaybackPositions.start, seconds),
        });
      return '-';
    } catch (e) {
      console.warn(
        `Warning: could not display duration label for ${seconds} seconds and ${gamePlaybackPositions.start}`,
      );
      console.error(e);
      return '-';
    }
  };

  const enableLogging = false;
  const handleLiveButtonClick = useCallback(() => {
    if (enableLogging) console.debug(`::liveedge : liveTimestampOffset ${liveTimestampOffset}`);
    if (enableLogging) console.debug(`::liveedge : livePlaybackDelay ${livePlaybackDelay}`);
    const sent = subMilliseconds(gamePlaybackPositions.live, liveTimestampOffset);
    if (enableLogging)
      console.debug(`::liveedge : current EndTimestamp  ${gamePlaybackPositions.live}`);
    if (enableLogging) console.debug(`::liveedge : sent EndTimestamp ${sent.getTime()}`);
    if (enableLogging) console.debug(`::liveedge : setTimestamp @ ${new Date().getTime()}`);

    setTimestamp(sent);
  }, [setTimestamp, endPositionSeconds, gamePlaybackPositions.head, liveTimestampOffset]);

  const handleJump = useCallback(
    (jumpSeconds: number) => {
      const newPosition = addSeconds(gamePlaybackPositions.head, jumpSeconds);
      setTimestamp(newPosition);
    },
    [setTimestamp, gamePlaybackPositions.head],
  );

  const handlePlayPause = (e: Event) => {
    Tracking.getInstance().track('Playback', {
      category: 'Controls',
      type: !isPlaying ? 'Pause' : 'Play',
      source: e.type === 'click' ? 'Video Control' : 'Keyboard Shortcut',
    });
    setPlaybackSpeed(isPlaying ? 0 : 1);
  };

  const handleJumpForwardOneSecond = useCallback(
    (e: Event) => {
      Tracking.getInstance().track('Playback', {
        category: 'Controls',
        unit: 'Second',
        type: 'Jump',
        direction: 'Forward',
        source: e.type === 'click' ? 'Video Control' : 'Keyboard Shortcut',
      });
      handleJump(1);
    },
    [handleJump],
  );
  const handleJumpBackwardOneSecond = useCallback(
    (e: Event) => {
      Tracking.getInstance().track('Playback', {
        category: 'Controls',
        unit: 'Second',
        type: 'Jump',
        direction: 'Backward',
        source: e.type === 'click' ? 'Video Control' : 'Keyboard Shortcut',
      });
      handleJump(-1);
    },
    [handleJump],
  );
  const handleJumpForwardOneFrame = useCallback(
    (e: Event) => {
      Tracking.getInstance().track('Playback', {
        category: 'Controls',
        unit: 'Frame',
        type: 'Jump',
        direction: 'Forward',
        source: e.type === 'click' ? 'Video Control' : 'Keyboard Shortcut',
      });
      jumpFrame(1);
    },
    [jumpFrame],
  );
  const handleJumpBackwardOneFrame = useCallback(
    (e: Event) => {
      Tracking.getInstance().track('Playback', {
        category: 'Controls',
        type: 'Jump',
        unit: 'Frame',
        direction: 'backward',
        source: e.type === 'click' ? 'Video Control' : 'Keyboard Shortcut',
      });
      jumpFrame(-1);
    },
    [jumpFrame],
  );

  const keyboardShortcutsModal = useKeyboardShortcutsDialog();

  usePlayerHotkeys(
    handlePlayPause,
    handleJumpForwardOneSecond,
    handleJumpBackwardOneSecond,
    handleJumpForwardOneFrame,
    handleJumpBackwardOneFrame,
  );


  const inLiveMode = useMemo(() => gameQuery?.data?.status==='live',[gameQuery?.data?.status])

  const isQuad = viewType === ViewType.MOSAIC;

  useEffect(() => {
    setIsLiveEdge(playbackPositionSeconds / endPositionSeconds > 0.95);
  }, [playbackPositionSeconds, endPositionSeconds]);

  return (
    <>
      <PlaybackPositionSlider
        showingBookmarks={showingBookmarks}
        gamePlaybackPositions={gamePlaybackPositions}
      />

      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        sx={{ overflowX: 'auto', p: 1 }}
      >
        <Stack direction="row" alignItems="center" spacing={1} px={2}>


          <PlayerControl
            title={displayCommand(KeyboardShortcuts.ShuttleBackward)}
            onClick={handleJumpBackwardOneSecond}
          >
            <OneSecondBackward />
          </PlayerControl>

          {!!ffAndRw && (
            <PlayerControl
              title="Rewind"
              onClick={() => {
                setPlaybackSpeed(-6);
                Tracking.getInstance().track('Playback', {
                  category: 'Controls',
                  type: 'Rewind',
                });
              }}
            >
              <FastRewindRoundedIcon />
            </PlayerControl>
          )}

          <PlayerControl
            title={displayCommand(KeyboardShortcuts.PlayPause)}
            onClick={handlePlayPause}
          >
            {isPlaying ? <PauseRoundedIcon /> : <PlayArrowRoundedIcon />}
          </PlayerControl>

          {!!ffAndRw && (
            <PlayerControl
              title="Fast Forward"
              onClick={() => {
                Tracking.getInstance().track('Playback', {
                  category: 'Controls',
                  type: 'Fast Forward',
                });
                setPlaybackSpeed(6);
              }}
            >
              <FastForwardRoundedIcon />
            </PlayerControl>
          )}

          <PlayerControl
            title={displayCommand(KeyboardShortcuts.ShuttleForward)}
            onClick={handleJumpForwardOneSecond}
          >
            <OneSecondForward />
          </PlayerControl>



          <Stack direction="row" spacing={2} sx={{ pl: 4 }}>
            <Typography style={{ fontVariantNumeric: 'tabular-nums' }} noWrap>
              {displayWallClock(new Date(gamePlaybackPositions.head))}
            </Typography>
            <Typography>|</Typography>
            <Typography noWrap>{durationLabel(endPositionSeconds)}</Typography>
          </Stack>
        </Stack>

        <Stack spacing={2} direction="row" divider={<Divider orientation="vertical" flexItem />}>
          <Stack direction="row" alignItems="center">
            {inLiveMode && (
              <Stack
                direction="row"
                spacing={1}
                style={{ marginRight: 20, cursor: 'pointer' }}
                onClick={handleLiveButtonClick}
              >
                <CircleRoundedIcon color={isLiveEdge ? 'error' : 'disabled'} sx={{ width: 16 }} />
                <Typography>Live</Typography>
              </Stack>
            )}
            <PlayerControl
              title={`${showingBookmarks ? 'Hide' : 'Show'} Bookmarks`}
              onClick={() => setShowingBookmarks(!showingBookmarks)}
              color={showingBookmarks ? PRIMARY_COLOR : SECONDARY_COLOR}
            >
              {showingBookmarks ? <BookmarkIcon /> : <BookmarkBorderIcon />}
            </PlayerControl>

            {!!ffAndRw && (
              <Tooltip title="Playback Speed" arrow>
                <Box>
                  <PlaybackSpeedSelectionButton disabled={isEditing} />
                </Box>
              </Tooltip>
            )}

            <PlayerControl
              title="Keyboard Shortcuts"
              onClick={() => keyboardShortcutsModal.show()}
              color={keyboardShortcutsModal.showing ? PRIMARY_COLOR : SECONDARY_COLOR}
            >
              <KeyboardIcon />
            </PlayerControl>
            <PlayerControl title="Streaming Stats" onClick={onStatsToggle}>
              <InfoOutlinedIcon />
            </PlayerControl>
          </Stack>

          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="subtitle1" noWrap>
              Select View:
            </Typography>

            <PlayerControl
              title="Quad Camera"
              onClick={() => selectMosaic('Video Player Toolbar')}
              color={isQuad ? PRIMARY_COLOR : SECONDARY_COLOR}
            >
              <GridViewRoundedIcon />
            </PlayerControl>

            <Tooltip title="Single Camera" arrow>
              <Box>
                <PlayerCameraFeedSelectionButton />
              </Box>
            </Tooltip>

            {isFullscreen ? (
              <PlayerControl title="Exit Fullscreen" onClick={exitFullscreen} color={PRIMARY_COLOR}>
                <FullscreenExitRoundedIcon />
              </PlayerControl>
            ) : (
              <PlayerControl title="Fullscreen" onClick={requestFullscreen} color={SECONDARY_COLOR}>
                <FullscreenRoundedIcon />
              </PlayerControl>
            )}
          </Stack>
        </Stack>
      </Box>
    </>
  );
};

export default PlayerControls;
