import { AutoIsoModel } from "@/features/autoIso/types/AutoIsoModel";
import { PlayerOption } from "../types/PlayerOption";

  export const setIsActive = (activeEntities: string[] | number[]) => (e: AutoIsoModel) =>
    ({
      ...e,
      isActive:
        activeEntities.find(
          (id) => Number.parseInt(id as string, 10) === e.id,
        ) !== undefined,
    }) as PlayerOption;