import { useQuery } from '@tanstack/react-query';

import { get } from '@truefit/http-utils';
import { UsersByGroups } from '../types';

export function getQueryKey(orgId) {
  return ['AllUsers', orgId];
}

export const useAllUsersQuery = (orgId?: number) =>
  useQuery({
    queryKey: getQueryKey(orgId),
    queryFn: async () => {
      const { data } = await get<UsersByGroups[]>(`users/list`);
      return data;
    },

    enabled: false,
    gcTime: 0,
  });

export default useAllUsersQuery;
