import React, { ReactNode, useState } from 'react';

type Props = {
  children: ReactNode;
};

type KeyboardShortcutsDialogState = {
  showing: boolean;
  setShowing: (showing: boolean) => unknown;
};

export const KeyboardShortcutsDialogContext = React.createContext<KeyboardShortcutsDialogState>({
  showing: false,
  setShowing: () => null,
});

const KeyboardShortcutsDialogProvider = ({ children }: Props) => {
  const [showing, setShowing] = useState(false);
  
  return (
    <KeyboardShortcutsDialogContext.Provider value={{ showing, setShowing }}>
      {children}
    </KeyboardShortcutsDialogContext.Provider>
  );
};

export default KeyboardShortcutsDialogProvider;
