import React from 'react';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Chip } from '@mui/material';
import { formatDistanceToNow } from 'date-fns';
import ActionMenu from './ActionMenu';
import { colorTag } from '@/shared/services/colorTag';

interface User {
  id: string;
  name: string;
  email: string;
  lastSeen: string;
  groups: string[];
  lastName: string;
  firstName: string;
}

interface UsersTableProps {
  users: User[];
  setSelectedUser?: (user: User) => void;
  setGroupOpen?: (open: boolean) => void;
  handleDelete: (id: number) => void;
  handleDisable: (id: number) => void;
  handleEditUserGroup: (user: User) => void;
}

export const UsersTable: React.FC<UsersTableProps> = ({
  users,
  handleDelete,
  handleEditUserGroup,
  handleDisable,
}) => {
  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <>
          {`${params.row.firstName || ''} ${params.row.lastName || ''}`}
          {params.row.disabled && (
            <Chip
              label="Disabled"
              size="small"
              color="error" // Optional: You can use "error" or other color variants for the Chip
              style={{ marginLeft: 8 }} // Optional: Add spacing between the name and Chip
            />
          )}
        </>
      )
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
    },
    {
      field: 'lastSeen',
      headerName: 'Last Seen',
      flex: 1,
      renderCell: (params: GridRenderCellParams<string>) => {
        if (!params.value) {
          return '-'; // Display '-' if lastSeen is undefined or null
        }
        return `${formatDistanceToNow(new Date(params.value))} ago`;
      },
    },
    {
      field: 'groups',
      headerName: 'Groups',
      flex: 1,
      renderCell: (params: GridRenderCellParams<string[]>) => (
        <>
          {params.value.map((group) => (
            <Chip
              key={group}
              label={group}
              size="small"
              style={{ marginRight: 4, color: colorTag(group).c, background: colorTag(group).b }}
            />
          ))}
        </>
      ),
    },
    {
      field: 'actions',
      headerName: '',
      sortable: false,
      renderCell: (params: GridRenderCellParams<User>) => (
        <ActionMenu
          user={params.row}
          onEdit={() => handleEditUserGroup(params.row)}
          onDelete={() => handleDelete(params.row.id)}
          onDisable={() => handleDisable(params.row.id)}
        />
      ),
    },
  ];

  return (
    <div style={{ width: '100%' }}>
      <DataGrid
        sx={{
          '& .disabled-row': {
            color: (theme) => theme.palette.text.disabled, // Access theme here
          },
          '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
            outline: 'none !important',
          },
        }}
        rows={users}
        columns={columns}
        getRowId={(row) => row.id}
        disableRowSelectionOnClick
        pageSizeOptions={[0, 5, 10, 20]}
        getRowClassName={(params) => (params.row.disabled ? 'disabled-row' : '')}
      />
    </div>
  );
};

export default UsersTable;
