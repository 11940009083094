import { useMutation, useQueryClient } from '@tanstack/react-query';
import { del } from '@truefit/http-utils';
import useCurrentGameId from '@/shared/hooks/useCurrentGameId';

import { getQueryKey as currentGameClipsQuery } from './useCurrentGameClipsQuery';

const useDeleteClipMutation = () => {
  const queryClient = useQueryClient();
  const gameId = useCurrentGameId();

  return useMutation({
    mutationFn: async (clipId: number) => {
      await del(`clips/${clipId}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['AllClips'] });
      queryClient.invalidateQueries({ queryKey: currentGameClipsQuery(gameId) });
    },
  });
};

export default useDeleteClipMutation;
