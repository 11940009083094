import pako from 'pako';
import { fromByteArray } from 'base64-js';
import { v4 } from 'uuid';
import { post } from '@truefit/http-utils';

interface EventPayload {
  eventName: string;
  data: any;
  ts: number;
}

interface UserProps {
  [key: string]: any;
}

class AnalyticsSDK {
  private eventsQueue: EventPayload[] = [];

  private isConnected: boolean = false;

  private sendTimer: NodeJS.Timeout | null = null;

  private userId: string | null = null;

  private userProps: UserProps = {};

  private maxBatchSize: number = 10;

  private maxRetries: number = 3;

  private retryDelay: number = 1000;

  private distinctid: string = v4();

  private enableLogging = false;

  constructor(private sendIntervalMs: number = 500) { }

  public initialize(): void {
    // Implement initialization logic here
    // Set up connections and configurations
    // Start the timer for periodic event sending
    window.addEventListener('online', this.handleConnectivityChange.bind(this));
    window.addEventListener('offline', this.handleConnectivityChange.bind(this));
    this.startSendTimer();
    this.handleConnectivityChange();
    if (this.enableLogging) console.debug('[CX analytics SDK] initialized');
  }

  public async trackEvent(eventName: string, data: any): Promise<void> {
    if(this.enableLogging) console.debug('[CX analytics SDK] trackEvent');
    const eventPayload: EventPayload = {
      eventName,
      ts: new Date().getTime(),
      data: {
        ...data,
        userId: this.userId,
        ...this.userProps,
      },
    };

    this.eventsQueue.push(eventPayload);

    if (this.eventsQueue.length >= this.maxBatchSize) {
      await this.sendQueuedEvents();
    }
  }

  public identifyUser(identifier: string, userProps: UserProps = {}): void {
    this.userId = identifier;
    this.userProps = userProps;
  }

  private async sendQueuedEvents(): Promise<void> {
    if (this.isConnected && this.eventsQueue.length > 0) {
      if (this.enableLogging) console.debug('[CX analytics SDK] sendQueuedEvents');
      if (this.enableLogging) console.debug('[CX analytics SDK] Queue length:', this.eventsQueue.length);
      const eventsToSend = this.eventsQueue.splice(0, this.maxBatchSize);
      await this.sendEventsToBackend(eventsToSend);
    }
  }

  private async sendEventsToBackend(events: EventPayload[], retryCount = 0): Promise<void> {
    if (this.enableLogging) console.debug('[CX analytics SDK] [batch] Queue length:', this.eventsQueue.length);

    const compressedData = pako.gzip(JSON.stringify(events));
    const base64Data = fromByteArray(compressedData);

    try {
      await post<void>(
        't',
        base64Data,

        () => ({
          headers: {
            'Content-Type': 'application/json',
            'Content-Encoding': 'gzip',
          },
        }),
      );
    } catch (error) {
      // console.error('[CX analytics SDK] Error sending events:', error);
      if (retryCount < this.maxRetries) {
        const nextRetryDelay = this.retryDelay * 2 ** retryCount;
        setTimeout(() => {
          if (this.enableLogging) console.debug('[CX analytics SDK] Retrying send events to backend');
          this.sendEventsToBackend(events, retryCount + 1);
        }, nextRetryDelay);
      } else {
        if (this.enableLogging) console.debug('[CX analytics SDK] Max retries reached, dropping events');
        this.eventsQueue = [...events, ...this.eventsQueue];
      }
    }
  }

  private async handleConnectivityChange(): Promise<void> {
    if (this.enableLogging) console.debug('[CX analytics SDK] handleConnectivityChange');

    this.isConnected = navigator.onLine;

    if (this.isConnected) {
      await this.sendQueuedEvents();
    }
  }

  private startSendTimer(): void {
    if (this.enableLogging) console.debug('[CX analytics SDK] startSendTimer');
    if (!this.sendTimer) {
      this.sendTimer = setInterval(async () => {
        if (this.isConnected) {
          await this.sendQueuedEvents();
        }
      }, this.sendIntervalMs);
    }
  }

  public immidiateSend(): void {
    if (this.enableLogging) console.debug('[CX analytics SDK] immidiateSend');
    this.sendQueuedEvents();
  }

  private stopSendTimer(): void {
    if (this.sendTimer) {
      clearInterval(this.sendTimer);
      this.sendTimer = null;
    }
  }
}

export default AnalyticsSDK;
