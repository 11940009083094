import * as React from 'react';
import { Divider, ListItemText, Stack, Typography, useTheme } from '@mui/material';
import useGameTypeMetadataQuery from '@/shared/hooks/useGameTypeMetadataQuery';
import { useTeamLogoPath } from '@/shared/hooks/useTeamLogoPath';
import { useGameState } from '@/shared/hooks/websocket/useGameState';
import { GameTiming } from '@/shared/types/GameStateModel';
import { PeriodNamingMapping } from '@/features/autoIso/types/GameTypeMetadataModel';
import { TeamColor } from '@/features/teams/types/TeamModel';

const getOrdinalSuffix = (i: number) => {
  const j = i % 10;
  const k = i % 100;
  if (j === 1 && k !== 11) return 'st';
  if (j === 2 && k !== 12) return 'nd';
  if (j === 3 && k !== 13) return 'rd';
  return 'th';
};

function splitNumber(n: number): [number, number] {
  const base = Math.floor(n / 100) * 100;
  const remainder = n % 100;
  return [base, remainder];
}

type TeamData = {
  abbreviation: string;
  externalid: string;
  name: string;
  score: number;
  teamColors: TeamColor[]
};

type Props = {
  data: {
    awayTeam: TeamData;
    homeTeam: TeamData;
    periodOfPlay: number;
    periodTimeRemaining: string;
    periodTimeElapsed: string;
    periodOrdinal: number;
    gameClock: string;
  };
};

const ScoreboardData: React.FC<Props> = ({ data }) => {
  const [gameState] = useGameState();
  const gameTypeMetadataQuery = useGameTypeMetadataQuery();
  
  const homeTeamPrimaryColor = React.useMemo(() => data.homeTeam.teamColors[0].hex_color, [data?.homeTeam?.teamColors]);
  const awayTeamPrimaryColor = React.useMemo(() => data.awayTeam.teamColors[0].hex_color, [data?.awayTeam?.teamColors]);

  // Memoize the period name calculation
  const periodName = React.useMemo(() => {
    if (!data.periodOfPlay) return '';
    
    const periodsMappings = gameTypeMetadataQuery.data?.periodNamingMapping[0] as PeriodNamingMapping;
    if (!periodsMappings) return `Period ${data.periodOfPlay}`;

    const { mappings } = periodsMappings;
    const [base, remainder] = splitNumber(data.periodOfPlay);
    if (base === 0 && remainder === 0) return 'NA';

    const periodName = mappings[base] || periodsMappings.periodName;
    return `${remainder}${getOrdinalSuffix(remainder)} ${periodName}`;
  }, [gameTypeMetadataQuery.data, data.periodOfPlay]);

  // Early return if gameState is not available
  if (!gameState?.game) {
    return <Stack direction="row" spacing={3} sx={{ flexGrow: 1, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }} />;
  }

  const { game: gameStateData } = gameState as { game: GameTiming };

  if (!data) {
    return <Stack direction="row" spacing={3} sx={{ flexGrow: 1, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }} />;
  }

  const awayLogoPath = useTeamLogoPath(data.awayTeam);
  const homeLogoPath = useTeamLogoPath(data.homeTeam);

  return (
    <Stack
      direction="row"
      alignItems="center"
      divider={<Divider orientation="vertical" flexItem />}
      sx={{ flexGrow: 1, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
    >
      {/* Away Team */}
      <Stack direction="row" spacing={3} alignItems="center">
        <Stack
          direction="row"
          spacing={0}
          alignItems="center"
          sx={{
            backgroundImage: `radial-gradient(circle, ${awayTeamPrimaryColor.concat('AA')} 0%, ${awayTeamPrimaryColor.concat('00')} 100%)`,
          }}
        >
          <img loading="lazy" width="40" src={awayLogoPath} alt={data.awayTeam.abbreviation} />
          <ListItemText primary={data.awayTeam.abbreviation} secondary={data.awayTeam.name} />
          <Typography
            variant="h4"
            fontWeight="bold"
            minWidth={50}
            textAlign={'center'}
            sx={(theme) => ({
              color: gameStateData.score.home <= gameStateData.score.away ? theme.palette.grey[50] : theme.palette.grey[500],
            })}
          >
            {gameStateData.score.away}
          </Typography>
        </Stack>

        <Typography variant="subtitle1" sx={(theme) => ({ color: theme.palette.grey[600] })}>
          @
        </Typography>

        {/* Home Team */}
        <Stack
          direction="row"
          spacing={0}
          alignItems="center"
          sx={{
            backgroundImage: `radial-gradient(circle, ${homeTeamPrimaryColor.concat('AA')} 0%, ${homeTeamPrimaryColor.concat('00')} 100%)`,
          }}
        >
          <Typography
            variant="h4"
            fontWeight="bold"
            minWidth={50}
            textAlign={'center'}
            sx={(theme) => ({
              color: gameStateData.score.home >= gameStateData.score.away ? theme.palette.grey[50] : theme.palette.grey[500],
            })}
          >
            {gameStateData.score.home}
          </Typography>
          <ListItemText
            primary={data.homeTeam.abbreviation}
            secondary={data.homeTeam.name}
            sx={{ '&.MuiListItemText-root': { textAlign: 'right' } }}
          />
          <img loading="lazy" width="40" src={homeLogoPath} alt={data.homeTeam.abbreviation} />
        </Stack>
      </Stack>

      {/* Game Clock and Period Name */}
      <Stack direction="row" spacing={2} alignItems="center">
        <Typography variant="h4" fontWeight="bold" minWidth={100}>
          {gameStateData.clock}
        </Typography>
        <Typography variant="subtitle1" fontWeight="bold" textTransform="uppercase" sx={(theme) => ({ color: theme.palette.grey[400] })}>
          {periodName}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default ScoreboardData;