import { useEffect } from 'react';
import useCamerasQuery from '@/features/camera/hooks/useCamerasQuery';

export const useCameraSelectionBindings = (onCameraSelectionChange: (cameraId: number) => void) => {
    const cameraSettingsStateQuery = useCamerasQuery();

    useEffect(() => {
        if (cameraSettingsStateQuery.status === 'success') {
            const cameras = cameraSettingsStateQuery.data.data; // Assume this contains the camera list

            const handleKeyDown = (e: KeyboardEvent) => {
                const key = parseInt(e.key, 10);
                if (key >= 1 && key <= 9) {
                    const camera = cameras[key - 1];
                    if (camera) {
                        e.preventDefault();
                        onCameraSelectionChange(camera.idx);
                    }
                }
            };

            window.addEventListener('keydown', handleKeyDown);
            return () => {
                window.removeEventListener('keydown', handleKeyDown);
            };
        }
    }, [cameraSettingsStateQuery.status, cameraSettingsStateQuery.data, onCameraSelectionChange]);
};

export default useCameraSelectionBindings;