

import { Org } from '@/types/user';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { post } from '@truefit/http-utils';

export const getOrgQueryKey = (orgId: number) => ['Org', orgId];

export type OrgUpdateModel = {
    defaultGroupId?: number;
    name?: string;
    tz?: string;
    domain?: string;
}

const updateOrgMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (model: OrgUpdateModel):Promise<Org> => {
            const response = await post<Org>(`/org`, model);
            return response.data;
        },
        onSuccess: () => queryClient.invalidateQueries({ queryKey: ['User'] })
    });
};

export default updateOrgMutation;
