import { useQuery } from '@tanstack/react-query';

import { get } from '@truefit/http-utils';

import { GameTypeMetadataModel } from '../../features/autoIso/types';
import useCurrentGameId from './useCurrentGameId';

export const getQueryKey = (gameId: number) => ['Games', gameId, 'TypeMetadata'];

const useGameTypeMetadataQuery = () => {
  const gameId = useCurrentGameId();

  return useQuery({
    queryKey: getQueryKey(gameId),
    queryFn: async () => {
      const response = await get<GameTypeMetadataModel>(`games/${gameId}/metadata`);
      return response.data;
    },
    gcTime: Infinity,
  });
};

export default useGameTypeMetadataQuery;
