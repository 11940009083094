import { useQuery } from '@tanstack/react-query';

import { get } from '@truefit/http-utils';

export function getQueryKey(orgId) {
  return ['OrgGroups', orgId];
}

export const useOrgGroupsQuery = (orgId?: number) =>
  useQuery({
    queryKey: getQueryKey(orgId),
    queryFn: async () => {
      const { data } = await get<any[]>(`org/groups`);
      return data;
    },
    enabled: true,
    gcTime: 0,
  });

export default useOrgGroupsQuery;
