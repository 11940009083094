import React from 'react';

import { Stack, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  keyboardKey: {
    paddingTop: theme.spacing(0.1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
    minHeight: '2em',
    minWidth: '2em',
    border: `1px solid ${theme.palette.grey[100]}`,
  },
}));

const KeyboardKey = ({ text }: { text: string }) => {
  const styles = useStyles();
  return (
    <Typography variant="caption" component="kbd" className={styles.keyboardKey}>
      {text}
    </Typography>
  );
};

type Props = {
  keys: string[];
};

const KeyboardCmd = ({ keys }: Props) => (
  <Stack direction="row" alignItems="center">
    {keys.map((k, i) => (
      <React.Fragment key={k}>
        {i > 0 && <span style={{ marginLeft: 5, marginRight: 5 }}>+</span>}
        <KeyboardKey text={k.toUpperCase()} />
      </React.Fragment>
    ))}
  </Stack>
);

export default KeyboardCmd;
