import { useQuery } from '@tanstack/react-query';

import { get } from '@truefit/http-utils';

export function getQueryKey(leagueId: number) {
  return ['GameTeams', leagueId];
}

const useGameTeamsQuery = (leagueId: number) =>
  useQuery({
    queryKey: getQueryKey(leagueId),
    queryFn: async () => {
      const { data } = await get('teams');
      return data;
    },
    gcTime: 0,
    enabled: false,
    placeholderData: [],
  });

export default useGameTeamsQuery;
