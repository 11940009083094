import * as React from 'react';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { LoadingButton } from '@mui/lab';
import SimpleBar from 'simplebar-react';

import { KeyboardShortcuts } from '@/shared/constants/keyboardShortcuts';
import useClipStateContext from '@/shared/hooks/useClipStateContext';
import useCurrentGameId from '@/shared/hooks/useCurrentGameId';
import ordinalNoun from '@/shared/services/ordinalNoun';

import { PANEL_PADDING } from '../../games/constants';
import useCurrentGameClipsQuery, { getQueryKey } from '../hooks/useCurrentGameClipsQuery';
import getClipsFilter from '../services/getClipsFilter';
import { ClipModel } from '../types';
import ClipRecord from './ClipRecord';
import useGameTeamsQuery from '@/features/games/hooks/useGameTeamsQuery';
import SectionTitle from '@/shared/components/SectionTitle';
import { UserStateContext } from '@/shared/components/UserStateProvider';
import { TagsFilter } from './TagsFilter';

const BookmarksPanel = () => {
  const gameId = useCurrentGameId();

  const {
    state: { isEditing, filter, tagsFilter },
    setFilter,
    setTagsFilter,
    handleAddBookmark,
  } = useClipStateContext();

  const { currentLeague } = React.useContext(UserStateContext);
  const queryClient = useQueryClient();
  
  const teams = useGameTeamsQuery(currentLeague?.id);

  const clipsQuery = useCurrentGameClipsQuery();
  const [filteredClips, setFilteredClips] = React.useState<ClipModel[]>([]);

  const viewingToolExpandablePanel = React.useRef(null);

  const simpleBar = React.useRef(null);

  React.useEffect(() => {
    if (clipsQuery.data) {
      setFilteredClips(clipsQuery.data.filter(getClipsFilter(filter, tagsFilter)));
    }
  }, [clipsQuery.dataUpdatedAt]);

  React.useEffect(() => {
    if (clipsQuery.data) {
      clipsQuery.refetch();
    }
  }, [tagsFilter, filter]);

  // do not put any react hook under this line ...
  if (!clipsQuery.data) return <CircularProgress m={2} />;

  return (
    <Stack sx={{ width: '100%', height: '100%' }}>
    
      <SectionTitle title={isEditing ? 'Clip Details' : 'Bookmarks'} padding={PANEL_PADDING} />

      {!isEditing && (
        <Stack>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            ml={PANEL_PADDING}
            mr={PANEL_PADDING - 1}
          >
            <Stack direction="row" spacing={1}>
              <LoadingButton
                startIcon={<AddIcon />}
                onClick={handleAddBookmark}
                disabled={isEditing}
                variant="outlined"
              >
                Bookmark ({KeyboardShortcuts.AddBookmark.keys.join('+')})
              </LoadingButton>
            </Stack>
            <Tooltip title="Refresh Bookmarks & Clips" arrow>
              <IconButton
                aria-label="refresh"
                onClick={() => queryClient.invalidateQueries({ queryKey: getQueryKey(gameId) })}
                sx={{ alignSelf: 'flex-end' }}
                disabled={isEditing}
              >
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </Box>

          <TagsFilter setTagsFilter={setTagsFilter} isEditing={isEditing}  /> 

          <Stack
            direction="row"
            spacing={1}
            my={2}
            mx={PANEL_PADDING}
            justifyContent="space-between"
            alignItems="flex-end"
            ref={viewingToolExpandablePanel}
          >
            <Typography
              variant="subtitle2"
              fontStyle="italic"
              textAlign="right"
              sx={{ opacity: 0.5, mx: PANEL_PADDING, flex: '1 1 0px' }}
            >
              Showing {ordinalNoun(filteredClips.length, 'Record', 'Records')}
            </Typography>
          </Stack>

          <Divider />
        </Stack>
      )}

      <SimpleBar style={{ color: 'white', height: '100%' }} ref={simpleBar}>
        {teams.status==='success' && filteredClips.map((row, i) => (
          <ClipRecord
            key={row.id}
            clip={row}
            isFirst={i === 0}
            teams={teams.data}
            downloadCliphandler={() => {}}
          />
        ))}
      </SimpleBar>
    </Stack>
  );
};

export default BookmarksPanel;
