import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Paper,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import { del } from '@truefit/http-utils';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import ReconnectingEventSource from 'reconnecting-eventsource';

import CosmLogo from '@/assets/cosm_logo.png';
import CXVideoHub from '@/assets/cx_video_hub.png';
import { useCreateSession } from '@/features/authentication/hooks';
import TimeMachineStatusProvider from '@/features/timeMachine/components/TimeMachineStatusProvider';
import { ExtendSessionDialog } from '@/shared/components';
import { GetGlobalSSEUrl } from '@/util';
import useCognito from '../../authentication/hooks/useCognito';
import { TimeApiResponse, useTimeApiData } from '@/shared/services/getTimeApiDate';

type Props = {
  children: ReactNode;
};

enum LocalSessionState {
  NOT_LOADED,
  LOADING,
  NO_LOCAL_SESSION,
  HAS_LOCAL_SESSION,
  AUTHENTICATED,
  READY,
}

const Item = styled(Box)(({ theme }) => ({
  backgroundColor: 'transparent',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const AuthenticateRoute = ({ children }: Props) => {
  const history = useHistory();
  const cognito = useCognito();
  const timeApiDataQuery = useTimeApiData();
  const interactiveSession = useCreateSession();

  const [sessionState, setSessionState] = useState<LocalSessionState>(
    cognito.authenticated ? LocalSessionState.AUTHENTICATED : LocalSessionState.NOT_LOADED,
  );
  const [newSession, setNewSession] = useState(false);
  const [loading, setLoading] = useState(true);
  const [progressMessage, setProgressMessage] = useState('Initializing');
  const [sessionHandler, setSessionHandler] = useState('');
  const [cognitoSession, setCognitoSession] = useState<CognitoUserSession | null>(null);
  const [isExpiringSession, setIsExpiringSession] = useState(false);
  const [clickCount, setClickCount] = useState(0);
  const [displaySecondaryMessage, setDisplaySecondaryMessage] = useState(false);
  const [secondaryMessage, setSecondaryMessage] = useState("You've unlocked the secret message!");

  const location = useLocation();
  const sse = useRef<ReconnectingEventSource | null>(null);

  const handleClick = () =>
    setClickCount((count) => {
      const newCount = count + 1;
      if (newCount === 3) setDisplaySecondaryMessage(true);
      return newCount;
    });

  const handleSignOut = () => {
    cognito.signOut({
      async onSuccess() {
        await del('session');
        history.push('/sign-in');
      },
      onFailure(e) {
        console.error(e);
        history.push('/sign-in');
      },
    });
  };

  useEffect(() => {
    if (cognito.authenticated) {
      interactiveSession.mutate(timeApiDataQuery.data as TimeApiResponse);
      setNewSession(true);
    }
  }, [timeApiDataQuery.status, cognito.authenticated]);

  const [progressValue, setProgressValue] = useState(0);

  const getRealtimeData = useCallback(
    (data, sessionId) => {
      const { event, payload } = data;
      if (payload?.sessionId !== sessionId) return;

      switch (event) {
        case 'initialized':
          if (!newSession) setLoading(false);
          break;
        case 'session-deploy-error':
          setProgressMessage(
            'Additional Player Unavailable - Unable to load a new video playback server. Support has been notified.',
          );
          setLoading(false);
          console.error(payload.message);
          break;
        case 'session-deploy-progress':
          if (payload.message.includes('1/6')) setProgressValue(16);
          else if (payload.message.includes('2/6')) setProgressValue(33);
          else if (payload.message.includes('3/6')) setProgressValue(50);
          else if (payload.message.includes('4/6')) setProgressValue(66);
          else if (payload.message.includes('5/6')) setProgressValue(83);
          else if (payload.message.includes('6/6')) setProgressValue(100);
          else if (payload.message.includes('Done')) setProgressValue(100);
          setProgressMessage(payload.message);
          break;
        case 'session-ready':
          setSessionState(LocalSessionState.READY);
          break;
        case 'session-handler':
          setSessionHandler(payload.handler);
          break;
        case 'session-ended':
          history.push(`/sign-in?returnUrl=${encodeURIComponent(location.pathname)}`);
          break;
        case 'session-expiring':
          setIsExpiringSession(true);
          break;
        default:
          return;
      }
    },
    [newSession],
  );

  useEffect(() => {
    if (sessionHandler) {
      cognito.setHttpAuth(cognitoSession, sessionHandler);
      cognito.updateCognitoContext({ sessionHandler });
    }
  }, [sessionHandler]);

  useEffect(() => {
    if (sessionState === LocalSessionState.NOT_LOADED) {
      cognito.getSession({
        onSuccess(userSession) {
          setCognitoSession(userSession);
          setSessionState(LocalSessionState.HAS_LOCAL_SESSION);
        },
        onFailure() {
          setSessionState(LocalSessionState.NO_LOCAL_SESSION);
        },
      });
    }
  }, [sessionState]);

  useEffect(() => {
    if (cognito.authenticated) {
      cognito.getSession({
        onSuccess(userSession) {
          setCognitoSession(userSession);
          if (userSession.getIdToken()) {
            const SSEHref = GetGlobalSSEUrl();
            if (!sse.current) {
              console.log('Creating new SSE');
              sse.current = new ReconnectingEventSource(SSEHref);
              sse.current.onmessage = (e) => {
                console.log('SSE Message:', e.data);
                getRealtimeData(JSON.parse(e.data), cognito.user.getUsername());
              };
            }
          }
          setSessionState(LocalSessionState.HAS_LOCAL_SESSION);
        },
        onFailure() {
          setSessionState(LocalSessionState.NO_LOCAL_SESSION);
        },
      });
    }
    return () => {
      sse.current?.close();
      sse.current = null;
    };
  }, [cognito.authenticated]);

  if (sessionState === LocalSessionState.NOT_LOADED || sessionState === LocalSessionState.LOADING) {
    return (
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
        <Stack>
          <Item>Loading...</Item>
          <Item>
            <CircularProgress color="inherit" />
          </Item>
        </Stack>
      </Backdrop>
    );
  }

  if (sessionState === LocalSessionState.NO_LOCAL_SESSION) {
    return (
      <Redirect
        to={{ pathname: '/sign-in', search: `?returnUrl=${encodeURIComponent(location.pathname)}` }}
      />
    );
  }

  if (
    sessionState === LocalSessionState.AUTHENTICATED ||
    sessionState === LocalSessionState.HAS_LOCAL_SESSION
  ) {
    return (
      <Container maxWidth="sm" fixed>
        <Box
          sx={{
            height: '100vh',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Paper
            sx={(theme) => {
              return {
                px: 3,
                pt: 2,
                pb: 1,
                borderRadius: 2,
                minWidth: 700,
                minHeight: 700,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                backgroundColor: 'transparent',
              };
            }}
          >
            <img src={CosmLogo} alt="Cosm" style={{ height: 40, marginTop: 15 }} />
            <img src={CXVideoHub} alt="CXVideo Hub" style={{ height: 10, marginTop: 15 }} />
            <Typography variant="subtitle2" sx={{ mb: 3, mt: 1 }}>
              with AUTO-ISO™
            </Typography>
            {loading && (
              <Item sx={{ mt: 5, mb: 10, width: '100%' }}>
                <CircularProgress color="inherit" />
              </Item>
            )}
            <Box sx={{ maxWidth: 550, textAlign: 'center' }} onClick={handleClick}>
              {progressMessage}
            </Box>

            <Box sx={{ maxWidth: 550, textAlign: 'center', pt: 4 }}>
              <Button onClick={handleSignOut}>{loading ? 'Cancel Login' : 'Back'}</Button>
            </Box>
            {displaySecondaryMessage && (
              <Box
                sx={{
                  maxWidth: 550,
                  textAlign: 'center',
                  mt: 2,
                  color: 'orange',
                  fontSize: 10,
                  fontFamily: 'monospace',
                }}
              >
                {secondaryMessage}
              </Box>
            )}
          </Paper>
        </Box>
      </Container>
    );
  }

  return (
    <TimeMachineStatusProvider>
      <ExtendSessionDialog open={isExpiringSession} onClose={() => setIsExpiringSession(false)} />
      {children}
    </TimeMachineStatusProvider>
  );
};

export default AuthenticateRoute;
