import React, { useContext, useEffect, useRef, useState } from 'react';

import { UserStateContext } from '@/shared/components/UserStateProvider';
import { useDocumentTitle } from '@/shared/hooks/useDocumentTitle';
import { formatStart } from '@/shared/services/formatStart';
import DownloadIcon from '@mui/icons-material/Download';
import { Box, Container, Divider, Tooltip, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PerfectScrollbar from 'react-perfect-scrollbar';
import AppPage from '../../../shared/components/PageComponents/AppPage';
import { useGamesQuery } from '../hooks/useGameQuery';
import { GameModelFromServer } from '../types';
import { ActivityIndicator } from './ActivityIndicator';
import GameCard from './GameCard';
import GamesForm, { DEFAULT_VALUES, FormValues as GamesFormValues } from './GamesForm';
import 'react-perfect-scrollbar/dist/css/styles.css';

const GamesPage = () => {
  const [formValues, setFormValues] = useState<GamesFormValues>(DEFAULT_VALUES);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [boxY, setBoxY] = useState(0);

  const boxRef = useRef<HTMLDivElement>(null);
  const gamesQuery = useGamesQuery(formValues);
  const userContext = useContext(UserStateContext);

  const total = gamesQuery.data?.pages[0].total;
  const allGames = gamesQuery.data?.pages.flatMap((p) => p.results);
  const hasMore = gamesQuery.hasNextPage && !gamesQuery.isError;
  const isLoading = gamesQuery.isLoading || gamesQuery.isFetchingNextPage;

  const { filteringByDate } = useFlags();

  const handleFilterAndSortChange = (values: GamesFormValues) => {
    setFormValues(values);
  };

  useEffect(() => {
    // Function to update the height on resize
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    // Add the resize event listener
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useDocumentTitle('Games');

  useEffect(() => {
    if (boxRef.current) {
      setBoxY(boxRef.current.getBoundingClientRect().top);
    }
  }, [boxRef.current]);

  const [gameBuckets, setGameBuckets] = useState({});

  const [gamesContainerHeight, setGamesContainerHeight] = useState(0);

  useEffect(() => {
    if (boxY && windowHeight) {
      setGamesContainerHeight(windowHeight - boxY - 110);
    }
  }, [boxY, windowHeight]);

  useEffect(() => {
    if (gamesQuery.status === 'success' && gamesQuery.data) {
      const updatedBuckets = {};

      allGames.forEach((game) => {
        const gameStartTimeStamp = new Date(game.startTimestamp);
        const formattedStartDate = formatStart(gameStartTimeStamp, userContext?.user.tz, 'short');

        if (!updatedBuckets[formattedStartDate]) {
          updatedBuckets[formattedStartDate] = {
            bucketFields: {
              year: gameStartTimeStamp.getFullYear(),
              month: gameStartTimeStamp.getMonth(),
              day: gameStartTimeStamp.getDate(),
            },
            games: new Set(),
            date: gameStartTimeStamp,
          };
        }

        updatedBuckets[formattedStartDate].games.add(game); // Add game to prevent duplicates
      });

      // Update gameBuckets to trigger re-sorting of buckets and games within each bucket
      setGameBuckets(updatedBuckets);
    }
  }, [gamesQuery.status, gamesQuery.isFetching, userContext?.user.tz]);

  return (
    <AppPage title="Games">
      <Container maxWidth="xl">
        <Typography variant="h6">Select a game</Typography>
        <Typography variant="body1">
          Choose between live and recorded games for a customized viewing experience.
        </Typography>
        <Divider sx={{ marginTop: 3 }} />

        <GamesForm onChange={handleFilterAndSortChange} />

        {total !== undefined ? (
          <Typography variant="h6" sx={{ marginTop: 2 }}>
            {total === 0 ? 'No games found.' : total > 1 ? `${total} Games` : `${total} Game`}
          </Typography>
        ) : null}

        {gamesQuery.isError ? (
          <Typography color="error" sx={{ textAlign: 'center' }}>
            An error ocurred while fetching games.
          </Typography>
        ) : null}
        <Box
          ref={boxRef}
          sx={{
            gap: 2,
            height: `${gamesContainerHeight}px`, // Set the height to make the container scrollable
          }}
        >
          <PerfectScrollbar>
            {filteringByDate &&
              Object.keys(gameBuckets).map((date) => (
                <Box key={date} sx={{ width: '100%' }}>
                  <Box
                    key={`d-${date}`}
                    sx={{
                      position: 'absolute',
                    }}
                  >
                    <Typography variant="h6">{date.split(' ')[0]}</Typography>
                    <Typography variant="h3" sx={{ fontWeight: 100 }}>
                      {gameBuckets[date].bucketFields.day}
                    </Typography>
                    <Typography variant="body1">{gameBuckets[date].bucketFields.year}</Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      marginLeft: 10,
                      marginTop: 15,
                      gap: 2,
                      flexWrap: 'wrap',
                      alignContent: 'flex-start',
                    }}
                  >
                    {Array.from(gameBuckets[date].games).map((game: GameModelFromServer) => (
                      <GameCard
                        key={game.id}
                        game={game}
                        cardWidth={444}
                        cardHeight={220}
                        // Apply a fade-in animation only to new games
                      />
                    ))}
                  </Box>
                </Box>
              ))}
          </PerfectScrollbar>
          <Box
            sx={{
              textAlign: 'center',
              height: '100px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {isLoading ? (
              <ActivityIndicator />
            ) : (
              hasMore && (
                <Tooltip title="Load more ...">
                  <IconButton
                    onClick={() => {
                      gamesQuery.fetchNextPage();
                    }}
                  >
                    <DownloadIcon />
                  </IconButton>
                </Tooltip>
              )
            )}
          </Box>
        </Box>
      </Container>
    </AppPage>
  );
};

export default GamesPage;
