import { useTeamLogoPath } from '@/shared/hooks/useTeamLogoPath';
import { Stack, Box, Typography, useTheme } from '@mui/material';
import React, { useMemo } from 'react';
import { TeamModel } from '../types/TeamModel';
import { motion } from 'framer-motion'; // Import motion

type Props = {
  team: TeamModel;
};

export const TeamHeader = ({ team }: Props) => {
  const theme = useTheme();

  const teamPrimaryColor = useMemo(() => {
    if (!team) return theme.palette.grey[800];
    if (!team.teamColors) return theme.palette.grey[800];
    return team.teamColors[0]?.hex_color || theme.palette.grey[800];
  }, [team, theme]);

  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{
        backgroundImage: `linear-gradient( ${teamPrimaryColor} 0%, ${teamPrimaryColor.concat('00')} 100%)`,
        padding: 1, // Add padding for better visual spacing
        justifyContent: 'center',
        alignItems: 'center',
        width: '50px',
      }}
    >
      {/* Logo with animation */}
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <img loading="lazy" width="40" src={useTeamLogoPath(team)} alt={team.abbreviation} />
      </motion.div>

      {/* Abbreviation with animation */}
      <motion.div
       style={{height: '20%'}}
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.3 }} // Delay the abbreviation animation
      >
        <Box>
          <Typography variant="body1">{team.abbreviation}</Typography>
        </Box>
      </motion.div>

      {/* Name with animation */}
      <motion.div
        style={{height: '50%'}}
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.6 }} // Delay the name animation
      >
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
          <Typography
            variant="caption"
            sx={{
              color: theme.palette.grey[400],
              transform: 'rotate(-90deg)', // Rotate text 90 degrees counterclockwise
              whiteSpace: 'nowrap', // Prevent text from wrapping
            }}
          >
            {team.name}
          </Typography>
        </Box>
      </motion.div>
    </Stack>
  );
};

export default TeamHeader;