import { useQuery } from '@tanstack/react-query';
import { get } from '@truefit/http-utils';
import { isUUID } from 'validator';

import toGameModel from '../services/toGameModel';
import { GameModelFromServer } from '../types';

export const queryKey = (gameId: string) => ['Games', gameId];

const DEFAULT_GAME_MODEL: GameModelFromServer = {
  id: 0,
  name: '',
  status: '',
  leagueid: 0,
  isLive: false,
  teams: [],
  cameras: [],
  startTimestamp: null,
  endTimestamp: null
};

const useGameDetailsQuery = (gameId?: string) =>
  useQuery({
    queryKey: queryKey(gameId),
    queryFn: async () => {
      try {
        if (!gameId || !isUUID(gameId)) return DEFAULT_GAME_MODEL;
        const { data } = await get<GameModelFromServer>(`games/${gameId}`);
        return toGameModel(data);
      } catch (e) {
        return DEFAULT_GAME_MODEL;
      }
    },
  });

export default useGameDetailsQuery;
