import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";

export const UpcomingIndicator = ({ color }: { color: string }) => (
  <Box
    sx={{
      px: 1,
      color,
      backgroundColor: '#2c3e50',
      border: '1px solid white',
      borderRadius: 1.5,
      p: 0.5,
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}
  >
    <Typography
      sx={{
        lineHeight: 1.25,
        fontSize: 12,
        textTransform: 'capitalize',
      }}
    >
      Upcoming
    </Typography>
  </Box>
);