import { useMutation, useQueryClient } from '@tanstack/react-query';
import { put } from '@truefit/http-utils';
import { getQueryKey as getCurrentGameClipsQueryKey } from '@/features/clips/hooks/useCurrentGameClipsQuery';
import { toClipModel } from '@/features/clips/services';
import { ClipModel, ClipModelFromServer } from '@/features/clips/types';
import useCurrentGameId from '@/shared/hooks/useCurrentGameId';


const useUpdateClipTagsMutation = () => {
  const gameId = useCurrentGameId();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (model: ClipModel) => {
      const response = await put<ClipModelFromServer>(`clips/${model.id}/tags`, model);
      return toClipModel(response.data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: getCurrentGameClipsQueryKey(gameId) });
    },
  });
};

export default useUpdateClipTagsMutation;
